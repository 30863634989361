import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('span',{staticStyle:{"position":"relative"}},[_c('h3',{staticClass:"pl-8 font-weight-regular",class:_vm.title=='\u00a0' ? 'pt-0' : 'pt-5'},[_vm._v(_vm._s(_vm.title)+" "),(_vm.description)?_c(VTooltip,{attrs:{"top":"","right":"","max-width":"600px","color":"transparent"},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c(VIcon,_vm._g({attrs:{"dense":"","right":"","color":"blue-grey lighten-2"}},on),[_vm._v("mdi-information")])]}}],null,false,3145590301)},[_c(VCard,{staticClass:"pa-3"},[_c(VCardTitle,[_vm._v("Descripción del contenido")]),_c(VCardText,[_c('span',{domProps:{"innerHTML":_vm._s(_vm.description)}})])],1)],1):_vm._e()],1),_c('div',{staticClass:"legend d-flex flex-rows",class:_vm.title=='\u00a0' ? 'mt-n4' : ''},_vm._l((_vm.datasets_info),function(dataset,idx){return _c(VCheckbox,{key:idx,staticClass:"mx-3",style:({ color: dataset.color }),attrs:{"label":dataset.name,"color":dataset.color,"hide-details":"","dense":""},on:{"click":_vm.updateDataVisibility},model:{value:(dataset.visible),callback:function ($$v) {_vm.$set(dataset, "visible", $$v)},expression:"dataset.visible"}})}),1),_c('div',{ref:_vm.id,staticClass:"dataviz"}),_c(VCard,{staticClass:"plantilla pa-2",attrs:{"id":`tooltip-${_vm.id}`}},[_c(VCardText,[_c(VRow,_vm._l((_vm.datasets_info),function(dataset){return (dataset.visible)?_c(VCol,{staticClass:"pa-0",attrs:{"cols":"12"}},[_c('span',{staticClass:"mr-1",style:({ color: dataset.color })},[_vm._v(_vm._s(dataset.name)+":")]),_vm._v(" "+_vm._s(Math.round(dataset.current_value*100)/100)+" ")]):_vm._e()}),1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }