<template>
    <v-dialog
        v-model="dialog"
        max-width="500px"
        scrollable
    >
        <v-card
            class="mx-auto blue-grey darken-4 rounded-lg px-3 py-6"
            dark
        >
            <v-system-bar color="transparent" class="mt-n8">
                <v-spacer></v-spacer>
                <v-btn  class="mt-12 pl-1" text plain icon @click="dialog = false">
                    <v-icon color="grey lighten-2" @click="close">mdi-close</v-icon>
                </v-btn>
            </v-system-bar>
            <v-card-title class="mb-8">{{ editedSector.id ? 'Editar sector' : 'Añadir sector' }}</v-card-title>
            <v-card-text>
                <div>
                    <v-autocomplete
                        v-model="editedSector.cropType"
                        label="Tipo de cultivo"
                        :items="['Vid', 'Olivo', 'Cereal', 'Hortaliza', 'Frutal', 'Trufa', 'Flor', 'Otro']"
                        required
                        outlined
                        class="rounded-lg grey darken-4 mb-4 mt-2"
                        rounded
                        dark
                        hide-details
                        prepend-inner-icon="mdi-format-list-bulleted-type"
                    ></v-autocomplete>
                    <v-autocomplete
                        v-model="editedSector.farmId"
                        label="Finca"
                        :items="farms"
                        item-text="name"
                        item-value="id"
                        required
                        outlined
                        class="rounded-lg grey darken-4 mb-4"
                        rounded
                        dark
                        hide-details
                        prepend-inner-icon="mdi-group"
                    >
                    <template v-slot:append-item>
                        <v-list-item>
                            <v-btn block dark class="pa-6 elevation-0 rounded-lg teal" @click="addFarmDialog = true">
                                <v-icon>mdi-plus</v-icon>
                                <strong>Añadir finca</strong>
                            </v-btn>
                            </v-list-item>
                        </template>    
                    </v-autocomplete>
                    <v-text-field
                        v-model="editedSector.name"
                        label="Nombre del sector"
                        outlined
                        hide-details
                        class="rounded-lg grey darken-4 mb-4"
                        rounded
                        dark
                        prepend-inner-icon="mdi-vector-square"
                    ></v-text-field>
                    <v-row class="pa-3 mt-8">
                        <!-- <span>Composición del terreno</span> -->
                        <v-autocomplete
                        v-model="selectedSoilContent"
                        label="Composición del terreno"
                        :items="soilContents"
                        item-text="name"
                        item-value="value"
                        return-object
                        outlined
                        hide-details
                        class="rounded-lg grey darken-4 mb-4"
                        rounded
                        dark
                        prepend-inner-icon="mdi-vector-square"
                        @change="assignSoilContentToSector"
                        :disabled="advanceSoilSelection"
                        ></v-autocomplete>
                    </v-row>
                    <v-row class="px-5">
                        <v-switch v-model="advanceSoilSelection" label="Avanzado" class="mt-4" color="teal lighten-1" @change="assignSoilContentToSector"></v-switch>
                    </v-row>
                    <div v-if="advanceSoilSelection">
                        <v-row>
                            <v-col cols="4" class="d-flex flex-column align-center">
                                <v-btn icon x-small @click="siltLock = !siltLock">
                                    <v-icon>mdi-lock</v-icon>
                                </v-btn>
                                <v-slider
                                    v-model="editedSector.siltPercent"
                                    vertical
                                    thumb-label="always"
                                    :thumb-size="33"
                                    thumb-color="lime darken-4"
                                    step="1"
                                    :min="0"
                                    :max="100"
                                    color="lime darken-4"
                                    :disabled="siltLock"
                                    @change="recalculateFieldPercentages('silt')"
                                >
                                    <template v-slot:thumb-label="{ value }">
                                        <span>{{ value }} %</span>
                                    </template>
                                </v-slider>
                            </v-col>
                            <v-col cols="4" class="d-flex flex-column align-center">
                                <v-btn icon x-small @click="clayLock = !clayLock">
                                    <v-icon>mdi-lock</v-icon>
                                </v-btn>
                                <v-slider
                                    v-model="editedSector.clayPercent"
                                    vertical
                                    thumb-label="always"
                                    :thumb-size="33"
                                    thumb-color="brown darken-4"
                                    step="1"
                                    :min="0"
                                    :max="100"
                                    color="brown darken-4"
                                    :disabled="clayLock"
                                    @change="recalculateFieldPercentages('clay')"
                                >
                                    <template v-slot:thumb-label="{ value }">
                                        <span>{{ value }} %</span>
                                    </template>
                                </v-slider>
                            </v-col>
                            <v-col cols="4" class="d-flex flex-column align-center">
                                <v-btn icon x-small @click="sandLock = !sandLock">
                                    <v-icon>mdi-lock</v-icon>
                                </v-btn>
                                <v-slider
                                    v-model="editedSector.sandPercent"
                                    vertical
                                    thumb-label="always"
                                    :thumb-size="33"
                                    thumb-color="amber lighten-3"
                                    step="1"
                                    :min="0"
                                    :max="100"
                                    color="amber lighten-3"
                                    :disabled="sandLock"
                                    @change="recalculateFieldPercentages('sand')"
                                >
                                    <template v-slot:thumb-label="{ value }">
                                        <span class="blue-grey--text text--darken-4">{{ value }} %</span>
                                    </template>
                                </v-slider>
                            </v-col>
                        </v-row>
                        <v-row class="px-3 py-0">
                            <v-col cols="4" class="text-center">
                                <p class="text-left pl-1 mb-0">% Limo</p>
                                <v-text-field
                                    v-model="editedSector.siltPercent"
                                    outlined
                                    hide-details
                                    class="rounded-lg lime darken-4 mb-4"
                                    rounded
                                    dark
                                    dense
                                    style="opacity: 0.8;"
                                    suffix="%"
                                    :disabled="siltLock"
                                    @change="recalculateFieldPercentages('silt')"
                                ></v-text-field>
                            </v-col>
                            <v-col cols="4" class="text-center">
                                <p class="text-left pl-1 mb-0">% Arcilla</p>
                                <v-text-field
                                    v-model="editedSector.clayPercent"
                                    outlined
                                    hide-details
                                    class="rounded-lg brown darken-4 mb-4"
                                    rounded
                                    dark
                                    dense
                                    style="opacity: 0.8;"
                                    suffix="%"
                                    :disabled="clayLock"
                                    @change="recalculateFieldPercentages('clay')"
                                ></v-text-field>
                            </v-col>
                            <v-col cols="4" class="text-center">
                                <p class="text-left pl-1 mb-0">% Arena</p>
                                <v-text-field
                                    v-model="editedSector.sandPercent"
                                    outlined
                                    hide-details
                                    class="rounded-lg amber lighten-3"
                                    rounded
                                    light
                                    dense
                                    style="opacity: 0.8;"
                                    suffix="%"
                                    :disabled="sandLock"
                                    @change="recalculateFieldPercentages('sand')"
                                ></v-text-field>
                            </v-col>
                        </v-row>
                    </div>
                </div>
            </v-card-text>
            <v-card-actions class="d-flex">
                <v-btn
                    class="flex-grow-1 pa-6 elevation-0 rounded-lg"
                    @click="close"
                >
                    Cerrar
                </v-btn>
                <v-btn
                    class="flex-grow-1 pa-6 elevation-0 rounded-lg teal"
                    @click="save"
                >
                    Guardar
                </v-btn>
            </v-card-actions>
        </v-card>
        <EditFarmDialogComponent v-model="addFarmDialog"/>
    </v-dialog>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import EditFarmDialogComponent from '@/components/EditFarmDialogComponent.vue'

export default {
    name: 'EditSectorDialogComponent',
    components: {
        EditFarmDialogComponent
    },
    props: {
        value: {
            type: Boolean,
            default: false
        },
        sector: {
            type: Object,
            default: () => this.defaultSector
        },
    },
    data: () => ({
        addFarmDialog: false,
        sandLock: false,
        clayLock: false,
        siltLock: false,
        remainingPercentage: 100,
        advanceSoilSelection: false,
        soilContents: [
            { name: "Suelo arenoso", value: { sandPercent: 90, siltPercent: 5, clayPercent: 5 }},
            { name: "Suelo limoso", value: { sandPercent: 20, siltPercent: 70, clayPercent: 10 }},
            { name: "Suelo arcilloso", value: { sandPercent: 20, siltPercent: 30, clayPercent: 50 }},
            { name: "Suelo franco", value: { sandPercent: 40, siltPercent: 40, clayPercent: 20 }},
            { name: "Suelo arenoso franco", value: { sandPercent: 60, siltPercent: 20, clayPercent: 20 }},
            { name: "Suelo franco arcilloso", value: { sandPercent: 35, siltPercent: 35, clayPercent: 30 }},
            { name: "Suelo franco limoso", value: { sandPercent: 30, siltPercent: 50, clayPercent: 20 }},
            { name: "Suelo franco arenoso", value: { sandPercent: 50, siltPercent: 30, clayPercent: 20 }},
            { name: "Suelo arcilloso arenoso", value: { sandPercent: 35, siltPercent: 15, clayPercent: 50 }}
        ],
        selectedSoilContent: null, // inicializar el valor seleccionado
        editedSector: {
            id: null,
            farmId: null,   
            name: '',
            cropType: 'Vid',
            coordinates: [],
            sandPercent: 33,
            clayPercent: 33,
            siltPercent: 33,
        },
        defaultSector: {
            id: null,
            farmId: null,   
            name: '',
            cropType: 'Vid',
            coordinates: [],
            sandPercent: 33,
            clayPercent: 33,
            siltPercent: 33,
        },
    }),
    computed: {
        ...mapGetters(['farms']),
        dialog: {
            get() {
                return this.value
            },
            set(val) {
                this.$emit('input', val)
            }
        },
        nLocks() {
            let n = 0;
            if (this.sandLock) n++;
            if (this.clayLock) n++;
            if (this.siltLock) n++;
            return n;
        },
        sumSoilPercents() {
            return this.editedSector.sandPercent + this.editedSector.clayPercent + this.editedSector.siltPercent;
        },
    },
    watch: {
        sector(val) {
            // if (val.id) {
                this.editedSector = Object.assign({}, this.sector)
            // } 
            // else {
            //     this.editedSector = Object.assign({}, this.defaultSector)
            // }
        },
        nLocks(val, oldVal) {
            if (val === 2 && oldVal === 1) {
                this.sandLock = true;
                this.clayLock = true;
                this.siltLock = true;
            } else if (val === 2 && oldVal === 3) {
                this.sandLock = false;
                this.clayLock = false;
                this.siltLock = false;
            }
        },
        sumSoilPercents(val) {
            if (val === 0) {
                this.assignSoilContentToSector()
            }
        },
    },
    methods: {
        ...mapActions(['insertSector', 'updateSector']),
        close() {
            this.dialog = false
            this.$emit('close', this.defaultSector)
        },
        async save() {
            if (this.sector.id) {
                this.updateSector(this.editedSector)
            } else {
                this.insertSector(this.editedSector)
            }
            this.$emit('save', this.defaultSector)
            this.dialog = false
        },
        recalculateFieldPercentages(type) {
            if (this.sandLock) {
                this.remainingPercentage = this.remainingPercentage - this.editedSector.sandPercent
            };
            if (this.clayLock) {
                this.remainingPercentage = this.remainingPercentage - this.editedSector.clayPercent
            };
            if (this.siltLock) {
                this.remainingPercentage = this.remainingPercentage - this.editedSector.siltPercent
            };
            if (!this.sandLock && !this.clayLock && !this.siltLock) this.remainingPercentage = 100;
            // Checking if type === 'sand'
            if (type === 'sand') {
                // If sand is being modified and clay is locked, then only adjust silt.
                if (this.clayLock) {
                    if (this.editedSector.sandPercent > 100 - this.editedSector.clayPercent) {
                        this.editedSector.sandPercent = 100 - this.editedSector.clayPercent;
                    }
                    this.editedSector.siltPercent = 100 - this.editedSector.sandPercent - this.editedSector.clayPercent;
                }
                // If sand is being modified and silt is locked, then only adjust clay.
                else if (this.siltLock) {
                    if (this.editedSector.sandPercent > 100 - this.editedSector.siltPercent) {
                        this.editedSector.sandPercent = 100 - this.editedSector.siltPercent;
                    }
                    this.editedSector.clayPercent = 100 - this.editedSector.sandPercent - this.editedSector.siltPercent;
                }
                // If sand is being modified, and both clay and silt are unlocked, adjust both equally.
                else {
                    this.editedSector.clayPercent = (this.remainingPercentage - this.editedSector.sandPercent) / 2;
                    this.editedSector.siltPercent = (this.remainingPercentage - this.editedSector.sandPercent) / 2;
                }
            } else if (type === 'clay') {
                if (this.sandLock) {
                    if (this.editedSector.clayPercent > 100 - this.editedSector.sandPercent) {
                        this.editedSector.clayPercent = 100 - this.editedSector.sandPercent;
                    }
                    this.editedSector.siltPercent = 100 - this.editedSector.clayPercent - this.editedSector.sandPercent;
                } else if (this.siltLock) {
                    if (this.editedSector.clayPercent > 100 - this.editedSector.siltPercent) {
                        this.editedSector.clayPercent = 100 - this.editedSector.siltPercent;
                    }
                    this.editedSector.sandPercent = 100 - this.editedSector.clayPercent - this.editedSector.siltPercent;
                } else {
                    this.editedSector.sandPercent = (this.remainingPercentage - this.editedSector.clayPercent) / 2;
                    this.editedSector.siltPercent = (this.remainingPercentage - this.editedSector.clayPercent) / 2;
                }
            } else if (type === 'silt') {
                if (this.sandLock) {
                    if (this.editedSector.siltPercent > 100 - this.editedSector.sandPercent) {
                        this.editedSector.siltPercent = 100 - this.editedSector.sandPercent;
                    }
                    this.editedSector.clayPercent = 100 - this.editedSector.siltPercent - this.editedSector.sandPercent;
                } else if (this.clayLock) {
                    if (this.editedSector.siltPercent > 100 - this.editedSector.clayPercent) {
                        this.editedSector.siltPercent = 100 - this.editedSector.clayPercent;
                    }
                    this.editedSector.sandPercent = 100 - this.editedSector.siltPercent - this.editedSector.clayPercent;
                } else {
                    this.editedSector.sandPercent = (this.remainingPercentage - this.editedSector.siltPercent) / 2;
                    this.editedSector.clayPercent = (this.remainingPercentage - this.editedSector.siltPercent) / 2;
                    console.log(this.editedSector.sandPercent, this.editedSector.clayPercent, this.editedSector.siltPercent)
                }
            }
        },
        assignSoilContentToSector() {
            this.editedSector.sandPercent = this.selectedSoilContent?.value.sandPercent ?? 30;
            this.editedSector.clayPercent = this.selectedSoilContent?.value.clayPercent ?? 30;
            this.editedSector.siltPercent = this.selectedSoilContent?.value.siltPercent ?? 40;
        },
    },
}
</script>

<style scoped>

</style>
  