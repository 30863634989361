<template>
    <v-container fluid>
        <v-row>
        <v-col cols="12" md="4">
            <h3>Fincas y Sectores</h3>
            <v-treeview :items="fincas" item-key="id" activatable @update:active="onItemSelected">
            <template v-slot:prepend="{ item }">
                <v-icon v-if="item.type === 'finca'">mdi-domain</v-icon>
                <v-icon v-if="item.type === 'sector'">mdi-map-marker</v-icon>
            </template>
            </v-treeview>
        </v-col>
        <v-col cols="12" md="8">
            <h3>Mapa del área seleccionada</h3>
            <div id="selected-map" style="width: 100%; height: 500px;"></div>
            <v-expansion-panels>
            <v-expansion-panel>
                <v-expansion-panel-header>Información del área seleccionada</v-expansion-panel-header>
                <v-expansion-panel-content>
                <v-simple-table>
                    <template v-slot:default>
                    <thead>
                        <tr>
                        <th class="text-left">Propiedad</th>
                        <th class="text-left">Valor</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(value, key) in selectedItemInfo" :key="key">
                        <td>{{ key }}</td>
                        <td>{{ value }}</td>
                        </tr>
                    </tbody>
                    </template>
                </v-simple-table>
                </v-expansion-panel-content>
            </v-expansion-panel>
            </v-expansion-panels>
        </v-col>
        </v-row>
    </v-container>
</template>

<script>
    import L from 'leaflet';
    
    export default {
    data() {
        return {
            selectedItemInfo: {},
            fincas: [
            {
                id: 1,
                name: 'Finca 1',
                type: 'finca',
                children: [
                { id: 11, name: 'Sector 1', type: 'sector', coords: [40.416775, -3.703790] },
                { id: 12, name: 'Sector 2', type: 'sector', coords: [40.417776, -3.704791] }
                ]
            },
            {
                id: 2,
                name: 'Finca 2',
                type: 'finca',
                children: [
                { id: 21, name: 'Sector 1', type: 'sector', coords: [40.418777, -3.705792] }
                ]
            }
            ],
            selectedMap: null
        };
    },
    mounted() {
        // Inicializar el mapa del área seleccionada
        this.selectedMap = L.map('selected-map').setView([40.416775, -3.703790], 10);
        L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
            attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
        }).addTo(this.selectedMap)
    },
    methods: {
        onItemSelected(selected) {
        if (selected.length === 0) return;

        const selectedItem = this.findItemById(this.fincas, selected[0]);
        if (selectedItem.type === 'sector') {
            this.selectedMap.setView(selectedItem.coords, 12);

            // Ejemplo de información ficticia para mostrar en el panel lateral
            this.selectedItemInfo = {
            'Nombre': selectedItem.name,
            'Tipo de terreno': 'Franco-arenoso',
            'Porcentaje de arena': '40%',
            'Porcentaje de limo': '40%',
            'Porcentaje de arcilla': '20%',
            'Tipo de riego': 'Goteo',
            'Cultivo asociado': 'Vid'
            };
        }
        },
        findItemById(tree, id) {
        for (const item of tree) {
            if (item.id === id) {
            return item;
            }
            if (item.children) {
            const found = this.findItemById(item.children, id);
            if (found) return found;
            }
        }
        }
    }
    };
</script>

<style>
/* Estilos personalizados para la pestaña de Fincas y Sectores */
</style>

  