import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VChip } from 'vuetify/lib/components/VChip';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VSystemBar } from 'vuetify/lib/components/VSystemBar';
import { VToolbarTitle } from 'vuetify/lib/components/VToolbar';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VDialog,{attrs:{"max-width":"500px","scrollable":""},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c(VCard,{staticClass:"grey darken-4 fill-height",staticStyle:{"position":"absolute","right":"0","top":"0","z-index":"9999"},attrs:{"dark":"","tile":"","max-width":"300px"}},[_c(VSystemBar,{staticClass:"pa-4 blue-grey darken-4",attrs:{"height":"50px"}},[_c(VToolbarTitle,{staticClass:"text-none"},[_vm._v("Notificaciones")]),_c(VSpacer),_c(VBtn,{attrs:{"icon":""},on:{"click":function($event){_vm.dialog = false}}},[_c(VIcon,{attrs:{"color":"grey lighten-2"}},[_vm._v("mdi-close")])],1)],1),_c(VCardText,{staticClass:"px-0"},_vm._l((_vm.notifications),function(notification,notificationIdx){return _c(VCard,{attrs:{"color":"transparent"},on:{"click":function($event){return _vm.markAsReadNotification(notification.id)}}},[(notificationIdx== 0 || !_vm.isSameDay(notification.createdAt, _vm.notifications[notificationIdx-1].createdAt))?_c(VCardTitle,{staticClass:"mb-n4 mt-4 ml-n1",staticStyle:{"font-size":"15px"}},[_vm._v(_vm._s(_vm.formatDate(notification.createdAt)))]):_vm._e(),_c(VCard,{staticClass:"blue-grey darken-4 mb-1",attrs:{"tile":""}},[_c(VCardTitle,{staticClass:"caption blue-grey--text text--lighten-2"},[_vm._v(" "+_vm._s(_vm.formatDate(notification.createdAt, true))+" "),(!notification.read)?_c(VChip,{staticClass:"ml-5 px-2",attrs:{"x-small":"","label":"","color":"teal"}},[_vm._v(" NUEVA ")]):_vm._e(),_c(VSpacer),_c(VBtn,{staticClass:"mt-n2",attrs:{"small":"","icon":"","text":""}},[_c(VIcon,{attrs:{"small":"","color":"grey"},on:{"click":function($event){return _vm.deleteNotification(notification.id)}}},[_vm._v("mdi-close")])],1)],1),_c(VCardText,{staticClass:"body-2 white--text"},[_vm._v(" "+_vm._s(notification.message)+" ")])],1)],1)}),1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }