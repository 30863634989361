<template>
    <v-dialog
        v-model="dialog"
        max-width="500px"
        scrollable
    >
        <v-card
            class="mx-auto blue-grey darken-4 rounded-lg px-3 py-6"
            dark
        >
            <v-system-bar color="transparent" class="mt-n8">
                <v-spacer></v-spacer>
                <v-btn  class="mt-12 pl-1" text plain icon @click="dialog = false">
                    <v-icon color="grey lighten-2">mdi-close</v-icon>
                </v-btn>
            </v-system-bar>
            <v-card-title class="mb-4">Añadir riego</v-card-title>
            <v-card-text>
                <div class="mb-12">
                    <div width="100%" class="d-flex rounded-lg grey darken-4">
                        <v-btn class="pa-4 flex-grow-1 rounded-lg text-caption text-none" active :color="tab!='historic' ? 'grey' : 'teal lighten-2'" small :style="tab=='historic' ? 'background-color: #003b2f;' : ''" :text="tab!='historic'" :outlined="tab=='historic'" @click="tab='historic'" :disabled="isFutureEvent">
                            Añadir a histórico
                        </v-btn>
                        <v-btn class="pa-4 flex-grow-1 rounded-lg text-caption text-none" :color="tab!='plan' ? 'grey' : 'teal lighten-2'" small :style="tab=='plan' ? 'background-color: #003b2f;' : ''" :text="tab!='plan'" :outlined="tab=='plan'" @click="tab='plan'" :disabled="!isFutureEvent">
                            Añadir al plan
                        </v-btn>
                    </div>
                </div>
                <div class="mb-15">
                    <v-row class="px-2 py-0">
                        <v-col cols="6" class="pa-1">
                            <v-autocomplete
                                v-model="editedEvent.type"
                                label="Actividad"
                                :items="activityTypes"
                                item-text="text"
                                item-value="value"
                                required
                                outlined
                                class="rounded-lg grey darken-4"
                                rounded
                                dark
                                hide-details
                                prepend-inner-icon="mdi-format-list-bulleted-type"
                                disabled
                            ></v-autocomplete>
                        </v-col>
                        <v-col cols="6" class="pa-1">
                            <v-text-field
                                v-model="editedEvent.volume"
                                label="Volumen"
                                required
                                outlined
                                class="rounded-lg grey darken-4"
                                rounded
                                dark
                                hide-details
                                append-icon="mdi-water"
                            ></v-text-field>
                        </v-col>
                    </v-row>
                    <v-row class="px-2 py-0">
                        <v-col cols="6" class="pa-1">
                            <v-text-field
                                v-model="editedEvent.startDate"
                                label="Fecha de inicio"
                                required
                                outlined
                                color=""
                                class="rounded-lg grey darken-4"
                                rounded
                                dark
                                hide-details
                                append-icon="mdi-calendar"
                            ></v-text-field>
                        </v-col>
                        <v-col cols="6" class="pa-1">
                            <v-text-field
                                v-model="editedEvent.endDate"
                                label="Fecha de fin"
                                required
                                outlined
                                class="rounded-lg grey darken-4"
                                rounded
                                dark
                                hide-details
                                append-icon="mdi-calendar"
                            ></v-text-field>
                        </v-col>
                    </v-row>
                </div>
                <div>
                    <v-autocomplete
                        v-model="editedEvent.farmId"
                        :items="farms"
                        label="Finca"
                        item-text="name"
                        item-value="id"
                        outlined
                        hide-details
                        class="rounded-lg grey darken-4 mb-3"
                        rounded
                        dark
                        append-icon="mdi-vector-square"
                        disabled
                    ></v-autocomplete>
                    <v-autocomplete
                        v-model="editedEvent.sectorId"
                        :items="sectors"
                        label="Sector"
                        item-text="name"
                        item-value="id"
                        outlined
                        hide-details
                        class="rounded-lg grey darken-4 mb-6"
                        rounded
                        dark
                        append-icon="mdi-group"
                        disabled
                    ></v-autocomplete>
                    <v-textarea
                    v-model="editedEvent.description"
                    label="Descripción"
                    outlined
                    hide-details
                    class="rounded-lg grey darken-4"
                    rounded
                    dark
                    append-icon="mdi-image-text"
                    >
                    </v-textarea>
                </div>
            </v-card-text>
            <v-card-actions class="d-flex">
                <v-btn
                    class="flex-grow-1 pa-6 elevation-0 rounded-lg"
                    @click="dialog = false"
                >
                    Cerrar
                </v-btn>
                <v-btn
                    class="flex-grow-1 pa-6 elevation-0 rounded-lg teal"
                    @click="save"
                >
                    Guardar
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

export default {
    name: 'DialogAddEventComponent',
    props: {
        value: {
            type: Boolean,
            default: false,
        },
        event: {
            type: Object,
            default: () => ({
                id: null,
            }),
        },
    },
    data() {
        return {
            name: '',
            tab: 'plan',  
            selected: '',
            editedEvent: {
                id: null,
                type: '',
                startDate: '',
                endDate: '',
                color: '',
                description: '',
            },
        };
    },
    computed: {
        ...mapGetters(['farms', 'activityTypes']),
        dialog: {
            get() {
                return this.value;
            },
            set(value) {
                this.$emit('input', value);
            },
        },
        sectors() {
            if (this.editedEvent.farmId) {
                return this.farms.find((farm) => farm.id === this.editedEvent.farmId).sectors;
            }
            return [];
        },
        isFutureEvent() {
            const today = new Date();
            const eventDate = new Date(this.editedEvent.startDate);
            return eventDate > today;
        },
    },
    watch: {
        dialog: {
            handler(newVal) {
                if (this.event.id) {
                    this.editedEvent = Object.assign({}, this.event);
                    this.editedEvent.volume = parseFloat(this.editedEvent.volume.toFixed(2));
                    this.tab = this.isFutureEvent ? 'plan' : 'historic';
                    this.editedEvent.isForecast = false;
                } else {
                    console.log('event', this.event)
                    const startDate = this.formatDate(new Date(this.event.year, this.event.month - 1, this.event.day));
                    const endDate = this.formatDate(new Date(this.event.year, this.event.month - 1, this.event.day));

                    this.editedEvent = {
                        id: null,
                        type: 'irrigation',
                        volume: 0,
                        sectorId: this.event.sectorId,
                        farmId: this.event.farmId,
                        startDate, // Formato 2023-03-10 08:00:00
                        endDate, // Formato 2023-03-10 08:00:00
                        isForecast: false,
                        description: '',
                    };
                    this.tab = this.isFutureEvent ? 'plan' : 'historic';
                    console.log('editedEvent', this.editedEvent);
                }
            },
            deep: true,
        },
    },
    methods: {
        ...mapActions(['insertEvent', 'updateEvent']),
        formatDate(date) {
            console.log('date', date);
            const year = date.getFullYear();
            let month = (date.getMonth() + 1).toString();
            let day = date.getDate().toString();
            let hour = date.getHours().toString();
            let minute = date.getMinutes().toString();
            let second = date.getSeconds().toString();

            month = month.length < 2 ? `0${month}` : month;
            day = day.length < 2 ? `0${day}` : day;
            hour = hour.length < 2 ? `0${hour}` : hour;
            // minute = minute.length < 2 ? `0${minute}` : minute;
            // second = second.length < 2 ? `0${second}` : second;

            // return `${year}-${month}-${day} ${hour}:${minute}:${second}`;
            return `${year}-${month}-${day}`;
        },
        save() {
            if (this.editedEvent.id) {
                this.updateEvent(this.editedEvent);
            } else {
                this.insertEvent(this.editedEvent);
            }
            this.dialog = false;
        },
        
    },
};
</script>

<style scoped>

</style>    
  