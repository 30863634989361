import Vue from "vue";
import VueRouter from "vue-router";
import store from '@/store';
import HomeView from "@/views/HomeView.vue";
import FarmsAndSectorsView from "@/views/FarmsAndSectorsView.vue";
import CalendarView from "@/views/CalendarView.vue";
import ForecastView from "@/views/ForecastView.vue";
import AnalysisView from "@/views/AnalysisView.vue";
import MainLayout from "@/layouts/MainLayout.vue";

Vue.use(VueRouter);

const routes = [
    {
        path: "/",
        name: "main",
        component: MainLayout,
        redirect: "/login",
        children: [
            {
                path: "/home",
                name: "home",
                component: HomeView,
            },
            {
                path: "/calendar",
                name: "calendar",
                component: CalendarView,
            },
            {
                path: "/forecast/:sectorId?",
                name: "forecast",
                component: ForecastView,
                props: true,
            },
            {
                path: "/analysis",
                name: "analysis",
                component: AnalysisView,
            },
            {
                path: '/logout',
                beforeEnter (to, from, next) {
                    store.dispatch('auth/logout');
                    next();
                },
            },
        ]
    },
    {
        path: "/login",
        name: "login",
        component: function () {
            return import("@/views/LoginView.vue");
        },
    },
    
];

const router = new VueRouter({
    mode: "history",
    base: process.env.BASE_URL,
    routes,
});

export default router;
