<template>
    <v-navigation-drawer
        v-model="drawer"
        permanent
        color="blue-grey darken-4"
        :mini-variant="miniVariant"
        
        dark
        app
        right
        class="pt-2"
    >
        <v-list
            dense
            nav
            class="py-0"
        >
            <v-list-item @click="miniVariant=!miniVariant" v-if="miniVariant">
                <v-list-item-icon>
                    <v-icon color="grey lighten-2">mdi-menu</v-icon>
                </v-list-item-icon>
            </v-list-item>
            <v-row v-else="miniVariant" class="pa-2">
                <v-col cols="9">
                    <v-img :src="require('@/assets/logo.png')" contain height="47" class="ml-n11 mt-n1"></v-img>
                </v-col>
                <v-col cols="3">
                    <v-btn icon @click="miniVariant=!miniVariant">
                        <v-icon color="grey lighten-2">mdi-chevron-right</v-icon>
                    </v-btn>
                </v-col>
            </v-row>
            <v-divider></v-divider>

                <v-list-item-group
                    v-model="selectedItemMenu"
                    mandatory
                >    
                    <template  
                        v-for="(item, index) in items"                
                    >
                        <v-list-item
                            :key="item.title"
                            link
                            :to="item.name != 'notifications' ? item.route : null"
                            @click="item.name == 'notifications' ? showNotifications = !showNotifications : null"
                            :inactive="item.name == 'notifications' ? true : false"
                            :class="item.name == 'notifications' ? 'cursor-pointer' : ''"
                        >
                            <template>
                                <v-list-item-icon>
                                    <v-badge
                                    overla  p
                                    :content="notReadNotifications"
                                    :value="item.name == 'notifications' ? notReadNotifications : null"
                                    color="teal"
                                    left
                                    >
                                    
                                        <v-icon color="grey lighten-2">{{ item.icon }}</v-icon>
                                    </v-badge>
                                    <!-- <v-badge
                                        v-if="item.notifications>0"
                                        overlap
                                        :content="item.notifications"
                                        color="warning"
                                    >
                                    </v-badge> -->
                                </v-list-item-icon>
                                <v-list-item-content>
                                    <v-list-item-title>{{ item.title }}</v-list-item-title>
                                </v-list-item-content>
                                
                            </template>
                        </v-list-item>
                    <!-- <v-divider v-if="index==2"></v-divider> -->
                </template>
            </v-list-item-group>
        </v-list>
        <NotificationsComponent v-model="showNotifications"></NotificationsComponent>
    </v-navigation-drawer>
</template>

<script>
import NotificationsComponent from '../components/notifications/NotificationsComponent.vue'
import { mapActions, mapGetters } from 'vuex'   

export default {
    name: 'DrawerComponent',
    components: {
        NotificationsComponent
    },
    props: {
        // miniVariant: {
        //     type: Boolean,
        //     default: true
        // }
    },
    data: () => ({
        miniVariant: true,
        selectedItemMenu: -1,
        drawer: false,
        showNotifications: false,
        items: [
            {
                title: 'Notificaciones',
                icon: 'mdi-bell',
                route: null,
                name: 'notifications',
            },
            {
                title: 'Inicio',
                icon: 'mdi-home',
                route: '/',
                name: 'home',
            },
            {
                title: 'Calendario',
                icon: 'mdi-calendar',
                route: '/calendar',
                name: 'calendar',
            },
            {
                title: 'Análisis',
                icon: 'mdi-history',
                route: '/analysis',
            },
            {
                title: 'Pronóstico',
                icon: 'mdi-weather-cloudy',
                route: '/forecast',
            },
            // {
            //     title: 'Configuración',
            //     icon: 'mdi-cog',
            //     route: '/settings',
            // },
        ],
    }),
    computed: {
        ...mapGetters(['notifications']),
        xsOnly() {
            return this.$vuetify.breakpoint.xsOnly;
        },
        mini() {
            return (this.xsOnly || this.miniVariant);
        },
        notReadNotifications() {
            return this.notifications.filter(notification => !notification.read).length
        },
    },
    methods: {
        ...mapActions(['getNotifications', 'getSectorsTasksStatus']),
        async loopNotifications() {
            await this.getNotifications()
            await this.getSectorsTasksStatus()
            setTimeout(() => {
                this.loopNotifications()
            }, 3000);
        },
    },
    created() {
        this.loopNotifications()
    },
}

</script>
<style scoped>
.cursor-pointer {
    cursor: pointer;
}
</style>
