<!-- ThumbnailMap.vue -->
<template>
    <div class="thumbnail-map" :id="'thumbnail-'+id"></div>
  </template>
  
  <script>
  import L from 'leaflet';
  import 'leaflet/dist/leaflet.css';
  
  export default {
    name: 'ThumbnailMap',
    props: {
        coordinates: {
            type: Array,
            required: true
        },
        id: {
            type: Number,
            default: null
        }
    },
    data: () => ({
        map: null
    }),
    mounted() {
        this.map = L.map('thumbnail-'+this.id, {
            center: this.getCenter(this.coordinates),
            zoom: 15,
            dragging: false,
            zoomControl: false,
            attributionControl: false
        });
    
        let polygon = L.polygon(this.coordinates, {
            color: "teal",weight: 2,
            opacity: 1,
            // fillOpacity: 0.2
            fillOpacity: 1
        })
        polygon.addTo(this.map)

        this.map.fitBounds(polygon.getBounds())
    },
    methods: {
        getCenter(coords) {
            const bounds = new L.LatLngBounds(coords);
            return bounds.getCenter();
        }
    }
  };
  </script>
  
  <style scoped>
    .thumbnail-map {
        width: 100%;
        height: 100%;
        z-index: 1;
    }
    .leaflet-container {
        background: transparent;
    }
  </style>
  