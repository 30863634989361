import axios from 'axios'
import store from '../store'
import router from '../router'

const api = axios.create()

api.defaults.baseURL = `${process.env.VUE_APP_API_HOST}:${process.env.VUE_APP_API_PORT}`;
api.defaults.headers.common["Content-Type"] = "application/json";
api.defaults.headers.common["Accept"] = "application/json";
// const token = localStorage.setItem('token', token)
// api.defaults.headers.common["Authorization"] = `Bearer ${token}`;
// api.defaults.headers.common["Access-Control-Allow-Origin"] = "*";
// api.defaults.headers.authorization = `Bearer ${
//     localStorage.getItem("user")
//         ? JSON.parse(localStorage.getItem("user")).token
//         : null
// }`;

api.interceptors.request.use(function (config) {
    const token = store.state.auth.token
    if (token) {
        config.headers.Authorization = `Bearer ${token}`
    }
    return config
}, function (error) {
    return Promise.reject(error)
})

api.interceptors.response.use(undefined, function (error) {
    if (error.response.status === 403) {
        store.dispatch('auth/logout')
        router.push('/login')
    }
    return Promise.reject(error)
})

export default api
