import api from '@/services/api'

export default {
    namespaced: false,
    state: {
        farms: [],
    },
    getters: {
        farms: state => state.farms,
    },
    mutations: {
        setFarms(state, farms) {
            state.farms = farms
        },
    },
    actions: {
        async getFarms({ commit, dispatch }) {
            api
            .get(`/farms`)
            .then(response => {
                if (response.data.success) {
                    commit('setFarms', response.data.farms)
                } else if (response.status === 401 || response.status === 403) {
                    // console.log(response.data.farms)
                    dispatch('goToLogin')
                }
            })
            .catch(function (error) {
                console.log(error)
                // dispatch('getFarms')
            })
        },
        
        insertFarm({ commit, dispatch }, farm) {
            api
            .put(`/farms`, { farm })
            .then(response => {
                if (response.data.success) {
                    // commit('setFarms', response.data.farms)
                    dispatch('getFarms')
                } else if (response.status === 401 || response.status === 403) {
                    // console.log(response.data.farms)
                    // dispatch('goToLogin')
                }
            })
            .catch(function (error) {
                console.log(error)
                // dispatch('getFarms')
            })
        },
        updateFarm({ commit, dispatch }, farm) {
            api
            .post(`/farms`, { farm })
            .then(response => {
                if (response.data.success) {
                    // commit('setFarms', response.data.farms)
                    dispatch('getFarms')
                } else if (response.status === 401 || response.status === 403) {
                    // console.log(response.data.farms)
                    // dispatch('goToLogin')
                }
            })
            .catch(function (error) {
                console.log(error)
                // dispatch('getFarms')
            })
        },
        deleteFarm({ commit, dispatch }, farmId) {
            api
            .delete(`/farms/${farmId}`)
            .then(response => {
                if (response.data.success) {
                    // commit('setFarms', response.data.farms)
                    dispatch('getFarms')
                } else if (response.status === 401 || response.status === 403) {
                    // console.log(response.data.farms)
                    // dispatch('goToLogin')
                }
            })
            .catch(function (error) {
                console.log(error)
                // dispatch('getFarms')
            })
        },
    },
};