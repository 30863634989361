<template>
    <v-dialog
        v-model="dialog"
        max-width="500px"
        scrollable
    >
        <v-card
            class="grey darken-4 fill-height"
            dark
            tile
            style="position:absolute; right: 0; top: 0; z-index: 9999;"
            max-width="300px"
        >
            <v-system-bar height="50px" class="pa-4 blue-grey darken-4">
                <v-toolbar-title class="text-none">Notificaciones</v-toolbar-title>
                <v-spacer></v-spacer>
                <v-btn icon @click="dialog = false">
                    <v-icon color="grey lighten-2">mdi-close</v-icon>
                </v-btn>
            </v-system-bar>
            <v-card-text class="px-0">
                <v-card color="transparent" v-for="(notification, notificationIdx) in notifications" @click="markAsReadNotification(notification.id)">
                    <v-card-title v-if="notificationIdx== 0 || !isSameDay(notification.createdAt, notifications[notificationIdx-1].createdAt)" class="mb-n4 mt-4 ml-n1" style="font-size:15px">{{ formatDate(notification.createdAt) }}</v-card-title>
                    <v-card tile class="blue-grey darken-4 mb-1">
                        <v-card-title class="caption blue-grey--text text--lighten-2">
                            {{formatDate(notification.createdAt, true) }}
                            <v-chip v-if="!notification.read" x-small label color="teal" class="ml-5 px-2">
                                NUEVA
                            </v-chip>
                            <v-spacer></v-spacer>
                            <v-btn class="mt-n2" small icon text>
                                <v-icon small color="grey" @click="deleteNotification(notification.id)">mdi-close</v-icon>
                            </v-btn>
                        </v-card-title>
                        <v-card-text class="body-2 white--text">
                            <!-- Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed non risus. Suspendisse lectus tortor, dignissim sit amet, adipiscing nec, ultricies sed, dolor. -->
                            {{ notification.message }}
                        </v-card-text>
                    </v-card>
                </v-card>
            </v-card-text>
        </v-card>
    </v-dialog>
</template>

<script>
import moment from 'moment'
import { mapActions, mapGetters } from 'vuex'

export default {
    name: 'NotificationsComponent',
    props: {
        value: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            tab: 'historic',  
        };
    },
    computed: {
        ...mapGetters(['notifications']),
        dialog: {
            get() {
                return this.value;
            },
            set(value) {
                this.$emit('input', value);
            },
        },
    },
    methods: {
        ...mapActions(['getNotifications', 'markAsReadNotification', 'deleteNotification']),
        formatDate(date, time=false) {
            if (time) {
                // return moment(date).locale('es').format('LLL');
                return moment(date).locale('es').format('D MMMM · HH:mm').toLowerCase();
            }
            return moment(date).locale('es').format('LL');
        },
        isSameDay(dateStr1, dateStr2) {
            const date1 = new Date(dateStr1);
            const date2 = new Date(dateStr2);
            return date1.getFullYear() === date2.getFullYear() &&
                date1.getMonth() === date2.getMonth() &&
                date1.getDate() === date2.getDate();
        },
    },
    created() {
        if (this.notifications.length === 0) this.getNotifications();
    },
};
</script>

<style scoped>

</style>    
  