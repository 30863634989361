import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VExpansionPanel } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanelContent } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanelHeader } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanels } from 'vuetify/lib/components/VExpansionPanel';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSimpleTable } from 'vuetify/lib/components/VDataTable';
import { VTreeview } from 'vuetify/lib/components/VTreeview';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VContainer,{attrs:{"fluid":""}},[_c(VRow,[_c(VCol,{attrs:{"cols":"12","md":"4"}},[_c('h3',[_vm._v("Fincas y Sectores")]),_c(VTreeview,{attrs:{"items":_vm.fincas,"item-key":"id","activatable":""},on:{"update:active":_vm.onItemSelected},scopedSlots:_vm._u([{key:"prepend",fn:function({ item }){return [(item.type === 'finca')?_c(VIcon,[_vm._v("mdi-domain")]):_vm._e(),(item.type === 'sector')?_c(VIcon,[_vm._v("mdi-map-marker")]):_vm._e()]}}])})],1),_c(VCol,{attrs:{"cols":"12","md":"8"}},[_c('h3',[_vm._v("Mapa del área seleccionada")]),_c('div',{staticStyle:{"width":"100%","height":"500px"},attrs:{"id":"selected-map"}}),_c(VExpansionPanels,[_c(VExpansionPanel,[_c(VExpansionPanelHeader,[_vm._v("Información del área seleccionada")]),_c(VExpansionPanelContent,[_c(VSimpleTable,{scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('thead',[_c('tr',[_c('th',{staticClass:"text-left"},[_vm._v("Propiedad")]),_c('th',{staticClass:"text-left"},[_vm._v("Valor")])])]),_c('tbody',_vm._l((_vm.selectedItemInfo),function(value,key){return _c('tr',{key:key},[_c('td',[_vm._v(_vm._s(key))]),_c('td',[_vm._v(_vm._s(value))])])}),0)]},proxy:true}])})],1)],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }