<template>
    <v-row class="no-gutters fill-height">
        <v-col cols="9">
            <MapComponent 
            v-if="farms.length>0" 
            id="map.1" 
            ref="mapComponent"
            :farms="farms" 
            :edit-mode="mapEditMode" 
            @edit-mode="mapEditMode=$event"
            @polygon="addSector" 
            @polygonClick="handlePolygonClick" 
            :height="mapHeight"
            :images="images.filter(image => image.date === imagesDates[selectedDay].date)"
            :selected-sector="selectedSector"
            @selected-sector="selectedSector=$event"
            />
            <v-card
                class="pa-0"
                height="50px"
                tile
                dark
            >
                <v-slide-group
                    class="fill-height no-gutters"
                    show-arrows
                    mandatory
                    v-model="selectedDay"
                >
                    <v-slide-item
                        v-for="date in imagesDates"
                        :key="`sat-${date.date}`"
                        v-slot="{ active, toggle }"
                        class="fill-height no-gutters"
                    >
                        <v-btn
                            :color="active ? 'teal darken-3' : ''"
                            class="ma-0 elevation-0"
                            dark
                            tile
                            @click="toggle"
                            height="100%"
                            width="100px"
                        >
                            <v-icon left>mdi-satellite-variant</v-icon>
                            <span class="text-caption text-capitalize ml-2">{{ date.day }} {{ months[date.month].abrev }} <br/>{{ date.year }}</span>
                        </v-btn>
                        
                    </v-slide-item>
                </v-slide-group>
            </v-card>
            <v-card
                v-if="selectedSector"
                class="no-gutters blue-grey darken-4"
                tile
                dark
            >
                <v-row style="margin-top: 0px; height: 300px;">
                    <!-- <v-col cols="2" class="pa-6 px8">
                        <v-autocomplete 
                            outlined 
                            color="dark" 
                            hide-details 
                            class="mb-1"
                            label="Índice"
                            :items="['NDVI', 'NDRE', 'SAVI']"
                        >
                        </v-autocomplete>
                        <v-autocomplete 
                            outlined 
                            color="dark" 
                            hide-details 
                            class="mt-1"
                            label="Datos meteorológicos"
                        >
                        </v-autocomplete>
                        <v-autocomplete 
                            outlined 
                            color="dark" 
                            hide-details 
                            class="my-1"
                            label="Fecha inicio"
                            append-icon="mdi-calendar"
                        >
                        </v-autocomplete>
                        <v-autocomplete 
                            outlined 
                            color="dark" 
                            hide-details 
                            class="my-1"
                            label="Fecha fin"
                            append-icon="mdi-calendar"
                        >
                        </v-autocomplete>
                    </v-col> -->
                    <v-col cols="12" class="pa-0">
                        <v-overlay :value="loading" :absolute="true">
                            <v-progress-circular
                                indeterminate
                                size="64"
                            ></v-progress-circular>
                        </v-overlay>
                        <v-card
                        v-if="selectedSector"
                        class="pa-0 elevation-0 fill-height"
                        style="background-color: #181F26;"
                        tile
                        dark
                        >
                            <D3AreaChart :data="chartData" id="chart" title="Condiciones actuales del campo" :description="chartDescription"/>
                        </v-card>
                    </v-col>
                </v-row>
            </v-card>
        </v-col>
        <v-col cols="3">
            <SectorsNavigationComponent v-model="selectedSector" :farms="farms" :index-evolution="indexEvolution" @add-sector="mapEditMode=true" :navigation-mode="true" :map-edit-mode="mapEditMode"/>
            <EditSectorDialogComponent v-model="editSectorDialog" :sector="addedSector" @save="onSaveSectorDialog" @close="onCloseSectorDialog"/>
        </v-col>
    </v-row>
</template>
<script>
    import MapComponent from '@/components/home/MapComponent.vue'
    import ThumbnailMap from '@/components/ThumbnailMap.vue'
    import D3AreaChart from '@/components/D3AreaChart.vue'
    import SectorsNavigationComponent from '@/components/SectorsNavigationComponent.vue'
    import EditSectorDialogComponent from '@/components/EditSectorDialogComponent.vue';

    import { mapGetters, mapActions } from 'vuex'
    import api from '@/services/api'


    export default {
        name: "HomeView",
        components: { 
            MapComponent, 
            ThumbnailMap, 
            D3AreaChart, 
            // CropComponent, 
            EditSectorDialogComponent,
            SectorsNavigationComponent  
        },
        data() {
            return {
                loading: false,
                selectedDay: 1,
                mapEditMode: false,
                selectedSector: null,
                editSectorDialog: false,
                addedSector: null,
                chartData: [],
                indicesHistory: [],
                indexEvolution: {
                    start: null,
                    end: null,
                    diff: null,
                    ndvi0: null,
                    ndvi1: null,
                    clp0: null,
                    clp1: null,
                },
                map: null,
                currentData: [],
                chartDescription: `
                <p>Esta gráfica muestra los parámetros de monitoreo clave para el sector seleccionado.</p>
                    <ul>
                        <li><strong>NDVI (Índice de Vegetación de Diferencia Normalizada)</strong>: Este índice es una medida de la cantidad y salud de la vegetación en el sector. Valores más altos indican vegetación más densa y sana.</li>
                        <li><strong>Temperatura</strong>: Muestra la variación de temperatura en el sector a lo largo del año. Es una variable importante que puede influir en la tasa de crecimiento de las plantas y la necesidad de riego.</li>
                        <li><strong>Precipitaciones</strong>: Este parámetro registra la cantidad de lluvia que ha caído desde el comienzo del año. Es un factor clave para determinar cuánto riego adicional se requiere.</li>
                        <li><strong>Evapotranspiración</strong>: Este es el total de la evaporación del suelo y la transpiración de las plantas. Es una medida de la cantidad de agua que las plantas han utilizado y es esencial para determinar cuánto riego necesitan.</li>
                    </ul>
                    <br>
                <p>Deslice el cursor sobre la gráfica para ver estos parámetros para una fecha específica.</p>
                `
            }
        },
        computed: {
            ...mapGetters(['farms', 'images', 'months']),
            mapHeight () {
                return this.selectedSector ? 'calc(100% - 50px - 300px)' : 'calc(100% - 50px)'
            },
            imagesDates() {
                if (this.images.length === 0) return []
                
                const dates = [...new Set(this.images.map(image => image.date))].sort()
                return dates.map(date => {
                    return this.getDateInfo(date)
                })
            },
        },
        watch: {
            selectedSector (val) {
                if (val) {
                    this.handleSectorClick(val)
                    this.$refs.mapComponent.zoomToSector(val)
                }
            },
            imagesDates (val) {
                if (val.length > 0) {
                    this.selectedDay = this.imagesDates.length - 1
                }
            },
            indicesHistory (val) {
                if (val.length > 0) {
                    
                    // const index = this.imagesDates.findIndex(date => date.epoch === this.selectedDay)
                    const indices = [...this.indicesHistory].sort((a, b) => a.date - b.date)
                    // console.log(indices)
                    const index0 = indices[indices.length - 5]
                    const index1 = indices[indices.length - 1]
                    const sign = index1.ndvi - index0.ndvi >= 0

                    this.indexEvolution = {
                        start: this.getDateInfo(index0.date),
                        end: this.getDateInfo(index1.date),
                        diff: (index1.ndvi - index0.ndvi).toFixed(2),
                        ndvi0: index0.ndvi.toFixed(2),
                        ndvi1: index1.ndvi.toFixed(2),
                        sign,
                        clp0: (index0.clp * 100).toFixed(1),
                        clp1: (index1.clp * 100).toFixed(1),
                    }
                }
            },
        },
        methods: {
            ...mapActions(['getFarms', 'insertSector', 'getImages']),
            // handleAction(item) {
            //     console.log(item)
            // },
            addSector (sector) {
                // this.insertSector({
                //     farmId: this.farms[0].id,
                //     name: 'Sector ' + (this.farms[0].sectors.length + 1),
                //     cropType: 'Trigo',
                //     ...sector
                // })
                this.addedSector = {
                    farmId: this.farms[0].id,
                    // name: 'Sector ' + (this.farms[0].sectors.length + 1),
                    name: '',
                    cropType: 'Vid',
                    sandPercent: 30,
                    clayPercent: 30,
                    siltPercent: 40,
                    ...sector
                }
                this.editSectorDialog = true
                this.mapEditMode = false
            },
            onSaveSectorDialog (val) {
                this.addedSector = val
            },
            onCloseSectorDialog (val) {
                this.addedSector = val
                this.$refs.mapComponent.removeEditableLayers()
            },
            getDateInfo (date) {
                const dateObj = new Date(date)
                return {
                    epoch: dateObj.getTime(),
                    date: date,
                    day: dateObj.getDate(),
                    month: dateObj.getMonth(),
                    year: dateObj.getFullYear(),
                }
            },
            handlePolygonClick (sector) {
                this.selectedSector = sector
                console.log(sector)
                // this.$refs.mapComponent.zoomToSector(sector)
            },
            async handleSectorClick (sector) {
                this.chartData = []
                this.loading = true
                await api.get(`/indices/${sector.id}/2023-01-01`)
                .then(response => {
                    this.indicesHistory = response.data.indices;
                    const clpFilter = 0.5
                    this.chartData = [...this.chartData, {
                        name: 'NDVI',
                        values: this.indicesHistory.filter(
                            item => item.clp < clpFilter
                        )
                        .map(item => {
                            return {
                                x: new Date(item.date),
                                y: item.ndvi,
                            }
                        }),
                        type: "line",
                        yAxis: [0, 1],
                        yAxisRight: true,
                    }]
                    
                    // console.log("chartData", this.chartData)
                })

                await api.get(`/history/${sector.id}/2023-01-01`)
                .then(response => {
                    // console.log(response.data);
                    const history_data = response.data.data.forecast.forecastday;
                    if (history_data.length > 1) {
                        // console.log("history_data", history_data)

                        this.chartData = [...this.chartData, {
                            name: 'Precipitaciones (mm)',
                            values: history_data.map(item => {
                                return {
                                    x: new Date(item.date),
                                    y: item.day.totalprecip_mm,
                                }
                            }),
                            type: "bar",
                            color: "#00bcd4",
                        }]

                        this.chartData = [...this.chartData, {
                            name: 'Temperatura (°C)',
                            values: history_data.map(item => {
                                return {
                                    x: new Date(item.date),
                                    y: item.day.avgtemp_c,
                                }
                            }),
                            type: "line",
                            color: "#ff9800",
                        }]

                        this.chartData = [...this.chartData, {
                            name: 'Evapotranspiración (mm)',
                            values: history_data.map(item => {
                                return {
                                    x: new Date(item.date),
                                    y: item.day.eto,
                                }
                            }),
                            type: "line",
                            // color: "#61E37B"
                        }]
                    }
                })
                this.loading = false

                
            },
            escListener(event) {
                if (event.key === 'Escape' || event.keyCode === 27) {
                    this.mapEditMode = false;
                }
            },
        },
        mounted() {
            this.getFarms()
            this.getImages()
            window.addEventListener('keyup', this.escListener);
        },
        beforeDestroy() {
            window.removeEventListener('keyup', this.escListener);
        },
    }
</script>

<style scoped>

</style>
