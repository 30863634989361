<template>
    <v-dialog
        v-model="dialog"
        max-width="750px"
        scrollable
    >
        <v-card
            class="mx-auto blue-grey darken-4 rounded-lg px-3 py-6"
            dark
        >
            <v-system-bar color="transparent" class="mt-n8">
                <v-spacer></v-spacer>
                <v-btn  class="mt-12 pl-1" text plain icon @click="dialog = false">
                    <v-icon color="grey lighten-2" @click="close">mdi-close</v-icon>
                </v-btn>
            </v-system-bar>
            <v-card-title class="mb-8">¿Estás seguro de que deseas eliminar el sector "{{ sector?.name }}"?</v-card-title>
            <v-card-actions class="d-flex">
                <v-btn
                    class="flex-grow-1 pa-6 elevation-0 rounded-lg elevation-0"
                    @click="close"
                >
                    Cancelar
                </v-btn>
                <v-btn
                    class="flex-grow-1 pa-6 elevation-0 rounded-lg red darken-3 elevation-0"
                    @click="remove"
                >
                    Eliminar
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import { mapActions } from 'vuex'

export default {
    name: 'DeleteSectorDialogComponent',
    components: {
    },
    props: {
        value: {
            type: Boolean,
            default: false
        },
        sector: {
            type: Object,
            default: null
        }
    },
    data: () => ({
    }),
    computed: {
        dialog: {
            get() {
                return this.sector ? this.value : false
            },
            set(val) {
                this.$emit('input', val)
            }
        },
    },
    methods: {
        ...mapActions(['deleteSector']),
        close() {
            this.dialog = false
        },
        remove() {
            this.deleteSector({
                sectorId: this.sector.id, 
                farmId: this.sector.farmId
            })
            this.dialog = false
        }
    },
}
</script>

<style scoped>

</style>
  