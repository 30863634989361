import api from '@/services/api'

export default {
    namespaced: false,
    state: {
        tasksStatus: [],
    },
    getters: {
        tasksStatus: state => state.tasksStatus,
    },
    mutations: {
        setTasksStatus(state, tasksStatus) {
            state.tasksStatus = tasksStatus
        }
    },
    actions: {
        async getSectorsTasksStatus({ commit, dispatch }) {
            api
            .get(`tasks/status`)
            .then(response => {
                if (response.data.success) {
                    commit('setTasksStatus', response.data.tasksStatus)
                } else if (response.status === 401 || response.status === 403) {
                    // console.log(response.data.images)
                    dispatch('goToLogin')
                }
            })
        },
    },
};