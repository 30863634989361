<template>
    <span style="position: relative;">
        <h3 class="pl-8 font-weight-regular"  :class="title=='\u00a0' ? 'pt-0' : 'pt-5'">{{ title }}
            <v-tooltip top right max-width="600px" color="transparent" v-if="description">
                <template v-slot:activator="{ on }">
                    <v-icon v-on="on" dense right color="blue-grey lighten-2">mdi-information</v-icon>
                </template>
                <v-card class="pa-3">
                    <v-card-title>Descripción del contenido</v-card-title>
                    <v-card-text>
                        <span v-html="description"></span>
                    </v-card-text>
                </v-card>
            <!-- <v-icon dense right color="blue-grey lighten-2">mdi-information</v-icon> -->
            </v-tooltip>
        </h3>
        <div class="legend d-flex flex-rows" :class="title=='\u00a0' ? 'mt-n4' : ''">
            <v-checkbox 
            v-for="(dataset, idx) in datasets_info" :key="idx" :style="{ color: dataset.color }"
            v-model="dataset.visible" 
            :label="dataset.name" 
            :color="dataset.color" 
            hide-details
            dense
            class="mx-3"
            @click="updateDataVisibility"
            ></v-checkbox>
        </div>
        <div :ref="id" class="dataviz"></div>
        <v-card :id="`tooltip-${id}`" class="plantilla pa-2">
            <v-card-text>
                <v-row>
                    <!-- <v-col cols="12">
                        <v-card-title class="text-h5">Plantilla</v-card-title>
                    </v-col> -->
                    <v-col cols="12" v-for="dataset in datasets_info" v-if="dataset.visible" class="pa-0">
                        <span class="mr-1" :style="{ color: dataset.color }">{{ dataset.name }}:</span> {{ Math.round(dataset.current_value*100)/100 }}
                    </v-col>
                </v-row>
            </v-card-text>
        </v-card>
    </span>
</template>
  
<script>
import * as d3 from 'd3'
import { timeFormatLocale } from 'd3-time-format';


// Definimos la configuración para el idioma español
const es_ES = {
  "dateTime": "%A, %e de %B de %Y, %X",
  "date": "%d/%m/%Y",
  "time": "%H:%M:%S",
  "periods": ["AM", "PM"],
  "days": ["domingo", "lunes", "martes", "miércoles", "jueves", "viernes", "sábado"],
  "shortDays": ["dom", "lun", "mar", "mié", "jue", "vie", "sáb"],
  "months": ["enero", "febrero", "marzo", "abril", "mayo", "junio", "julio", "agosto", "septiembre", "octubre", "noviembre", "diciembre"],
  "shortMonths": ["ene", "feb", "mar", "abr", "may", "jun", "jul", "ago", "sep", "oct", "nov", "dic"]
};

// Asignamos esta configuración a la función de formateo de fechas
// const formatTime = timeFormatLocale(es_ES).format.replace(/(^0+)/, "");
const formatTime = timeFormatLocale(es_ES).format


export default {
    name: "D3AreaChart",
    props: {
        id: {
            type: String,
            required: true
        },
        data: {
            type: Array,
            default: () => [],
            required: true
        },
        title: {
            type: String,
            required: false,
            default: "\u00a0"
        },
        description: {
            type: String,
            required: false,
            default: null
        }
    },
    data() {
        return {
            datasets_info: [],
            dataElements: {},
            // colors: ["#69b3a2", "#404080", "#ff9900", "#3366cc", "#109618", "#990099", "#dc3912"],
            colors: ["#61E37B", "#1CE6DC", "#03A7D4", "#0075B8", "#1F5282 ", "#DE6B48", "#DAEDBD"],
            margin: {
                top: 10,
                right: 30,
                bottom: 30,
                left: 60
            },
            svg: null,
            width: 0,
            height: 0
        }
    },
    computed: {
        filteredData() {
            return this.data.filter((dataset, idx) => {
                return this.datasets_info[idx] ? this.datasets_info[idx].visible : true;
            });
        }
    },
    watch: {
        data() {
            // console.log(this.data)
            this.removeChart();
            this.calculateSize();
            this.drawSvg();
            window.addEventListener("resize", this.updateSize);
            this.drawChart(this.filteredData);
            this.datasets_info = this.data.map((dataset, idx) => {
                return {
                    name: dataset.name,
                    color: dataset.color ?? this.colors[idx],
                    visible: true,
                    current_value: null
                }
            });
            this.updateDataVisibility();
        },
        // datasets_info() {
        //     // console.log(this.data)
        //     this.removeChart();
        //     this.calculateSize();
        //     this.drawSvg();
        //     window.addEventListener("resize", this.updateSize);
        //     this.drawChart(this.data);
        //     // this.datasets_info = this.data.map((dataset, idx) => {
        //     //     return {
        //     //         name: dataset.name,
        //     //         color: this.colors[idx],
        //     //         visible: true,
        //     //         current_value: null
        //     //     }
        //     // });
        // }
    },
    mounted() {
        this.calculateSize();
        this.drawSvg();
        // this.drawChart(this.data);
        window.addEventListener("resize", this.updateSize);
    },
    beforeDestroy() {
        window.removeEventListener("resize", this.updateSize);
    },
    methods: {
        updateDataVisibility() {
            Object.entries(this.dataElements).forEach(([datasetName, dataElement]) => {
                const isVisible = this.datasets_info.find(datasetInfo => datasetInfo.name === datasetName).visible;
                // dataElement.attr("opacity", isVisible ? 1 : 0)
                dataElement //.attr("opacity", isVisible ? 0 : 1)
                        .transition()
                        .duration(500)
                        .attr("opacity", isVisible ? 1 : 0)
            });
        },
        calculateSize() {
            const container = this.$refs[this.id];
            this.width = container.offsetWidth - this.margin.left - this.margin.right;
            this.height = container.offsetHeight - this.margin.top - this.margin.bottom;
        },
        drawSvg() {
            this.svg = d3.select(this.$refs[this.id])
                .append("svg")
                .attr("width", this.width + this.margin.left + this.margin.right)
                .attr("height", this.height + this.margin.top + this.margin.bottom)
                .append("g")
                .attr("transform", `translate(${this.margin.left},${this.margin.top})`);
        },
        updateSize() {
            this.calculateSize();
            d3.select(this.$refs[this.id]).select("svg").remove();
            this.drawSvg();
            // this.loadData();
        },
        removeChart() {
            d3.select(this.$refs[this.id]).select("svg").remove();
        },
        // chartDataLength(x_extent) {
        //     // Calcula la diferencia en milisegundos entre las dos fechas
        //     var diff = x_extent[x_extent.length - 1].getTime() - x_extent[0].getTime();

        //     // Convierte la diferencia de milisegundos a días
        //     return Math.floor(diff / (1000 * 60 * 60 * 24)) + 1;
        // },
        drawChart(data) {
            var x_extent = data.map(dataset => dataset.values.map(d => d.x)).flat().sort((a, b) => a - b);
            x_extent = [...new Set(x_extent.map(fecha => fecha.toISOString().slice(0,10)))].map(fecha => new Date(fecha));
            // console.log("x_extent", x_extent);
            var n_items = x_extent.length;

            // Eje Y izquierdo principal
            var y_min = data.filter(dataset => !dataset.yAxisRight).map(dataset => dataset.yAxis ? dataset.yAxis[0] : dataset.values.map(d => d.y)).flat().reduce((a, b) => Math.min(a, b), 9999999);
            var y_max = data.filter(dataset => !dataset.yAxisRight).map(dataset => dataset.yAxis ? dataset.yAxis[1] : dataset.values.map(d => d.y)).flat().reduce((a, b) => Math.max(a, b), -9999999);

            // Eje Y derecho
            var y_right_min = data.filter(dataset => dataset.yAxisRight).map(dataset => dataset.yAxis ? dataset.yAxis[0] : dataset.values.map(d => d.y)).flat().reduce((a, b) => Math.min(a, b), 9999999);
            var y_right_max = data.filter(dataset => dataset.yAxisRight).map(dataset => dataset.yAxis ? dataset.yAxis[1] : dataset.values.map(d => d.y)).flat().reduce((a, b) => Math.max(a, b), -9999999);
            
            var x = d3.scaleTime()
                // .domain(d3.extent(data[0].values, d => d.x))
                .domain(d3.extent(x_extent, d => d))
                .range([0, this.width]);

            var tickValues = x_extent.filter((d, i) => i % 10 === 0);
            
            this.svg.append("g")
                .attr("transform", `translate(0,${this.height})`)
                .call(d3.axisBottom(x)
                    .tickValues(tickValues) // Usamos los valores generados anteriormente
                    // .tickFormat(d3.timeFormat("%d %b"))); // Formato de las fechas en el eje X.
                    .tickFormat(d => formatTime("%d %b")(d).replace(/(^0+)/, ""))
                ) // Formato de las fechas en el eje X.
                .attr("color", "#888") // color de eje X
                // Selecciona todos los ticks
                .selectAll(".tick")
                // Añade una línea a cada tick
                .append("line")
                    .attr("class", "gridline")
                    .attr("stroke", "#888") // color de la línea
                    // .attr("stroke-dasharray", "5,5") // línea discontinua
                    .attr("stroke-width", 0.5)
                    .attr("y1", 0)
                    .attr("y2", -this.height);

            // Eje Y izquierdo principal
            var y = d3.scaleLinear()
                // .domain([0, d3.max(data[0].values, d => d.y)])
                .domain([y_min, y_max])
                .range([this.height, 0]);

            this.svg.append("g")
                .call(d3.axisLeft(y))
                .attr("color", "#888"); // color de eje Y

            if (this.data.find(dataset => dataset.yAxisRight)) {
                // Eje Y derecho
                var y_right = d3.scaleLinear()
                    // .domain([0, d3.max(data[0].values, d => d.y)])
                    .domain([y_right_min, y_right_max])
                    .range([this.height, 0]);

                this.svg.append("g")
                    .attr("transform", `translate(${this.width},0)`)
                    .call(d3.axisRight(y_right))
                    .attr("color", "#888"); // color de eje Y
            }

            // Añadir rectángulo de fondo desde hoy
            var today = new Date();
            today = new Date(today.getFullYear(), today.getMonth(), today.getDate());
            if (this.width - x(today) > 0) {
                this.svg.append("rect")
                    .attr("x", x(today))
                    .attr("y", 0)
                    .attr("width", this.width - x(today))
                    .attr("height", this.height)
                    .attr("fill", "lightgray")
                    .attr("opacity", 0.1);
            }

            if (this.data.find(dataset => dataset.isForecast)) {
                // Añadir en la parte superior izquierda del rectángulo una etiqueta llamada "Pronóstico"
                this.svg.append("text")
                    .attr("x", x(today) + 10)
                    .attr("y", 20)
                    .attr("fill", "white")
                    .attr("font-size", "12px")
                    .text("Pronóstico")
                    .attr("opacity", 0.6);
            }

            // Añadir eje X en 0 para las barras
            this.svg.append("line")
                .attr("x1", 0)
                .attr("y1", y(0))
                .attr("x2", this.width)
                .attr("y2", y(0))
                .attr("stroke", "#888")
                .attr("stroke-width", 0.5);

            var bisect = d3.bisector(function (d) { return d.x; }).left;

            var focus = [];
            // Genera una línea para cada conjunto de datos y un punto de enfoque
            data.forEach((dataset, idx) => {
                const color = dataset.color ?? this.colors[idx % this.colors.length];
                const t = d3.transition().duration(2000); // Define una transición de 2 segundos
                const lineGenerator = d3.line()
                    .curve(d3.curveCardinal.tension(0.5))
                    .x((d) => { return x(d.x); })
                    .y((d) => { 
                        if (dataset.yAxisRight) {
                            return y_right(d.y); 
                        }
                        return y(d.y); 
                    })
                if (dataset.type == "area") {
                    // Primero, define el degradado.
                    var gradient = this.svg.append("defs")
                    .append("linearGradient")
                    .attr("id", `gradient-${idx}`)
                    .attr("x1", "0%")
                    .attr("y1", "0%")
                    .attr("x2", "100%")
                    .attr("y2", "100%")
                    .attr("spreadMethod", "pad");

                    gradient.append("stop")
                    .attr("offset", "0%")
                    .attr("stop-color", color)
                    .attr("stop-opacity", 0.2);

                    gradient.append("stop")
                    .attr("offset", "70%")
                    .attr("stop-color", color)
                    .attr("stop-opacity", 0.05);

                    gradient.append("stop")
                    .attr("offset", "85%")
                    .attr("stop-color", color)
                    .attr("stop-opacity", 0);

                    this.dataElements[dataset.name] = this.svg.append("path")
                    .datum(dataset.values)
                    .attr("fill", `url(#gradient-${idx})`)
                    .attr("stroke", color) // Podrías tener una función que asigna colores basada en el nombre del conjunto de datos
                    .attr("stroke-width", 2.5)
                    .attr("d", lineGenerator) // Asume que ya tienes una función de línea D3 establecida

                } else if (dataset.type == "line") { 
                    this.dataElements[dataset.name] = this.svg.append("path")
                    .datum(dataset.values)
                    .attr("fill", "none")
                    .attr("stroke", color) // Podrías tener una función que asigna colores basada en el nombre del conjunto de datos
                    .attr("stroke-width", 2.5)
                    .attr("d", lineGenerator) // Asume que ya tienes una función de línea D3 establecida
                    // .attr('stroke-dasharray', function() {
                    //     const totalLength = this.getTotalLength();
                    //     return totalLength + " " + totalLength;
                    // })
                    // .attr('stroke-dashoffset', function() {
                    //     return this.getTotalLength();
                    // })
                    // .transition(t) // Aplica la transición
                    // .attr('stroke-dashoffset', 0);
                    
                    // .attr('opacity', 0)
                    // .transition()
                    // .duration(500)
                    // .attr('opacity', 1)
                } 
                else if (dataset.type == "bar") {
                    // Crear un grupo para cada conjunto de barras
                    // var barGroup = this.svg.append("g")
                    this.dataElements[dataset.name] = this.svg.append("g")
                        .attr("fill", color)
                        // .classed(`path-${idx}`, true)
                    
                    var barWidth = this.width / n_items * 1 / 2;

                    // Crear las barras dentro de ese grupo
                    // barGroup.selectAll("rect")
                    this.dataElements[dataset.name]
                        .selectAll("rect")
                        .data(dataset.values)
                        .enter()
                        .append("rect")
                        .attr("x", d => x(d.x) - barWidth / 2)
                        .attr("y", d => y(d.y))
                        .attr("height", d => {
                            if (dataset.yAxisRight) {
                                // return y_right(0) - y_right(d.y);
                                return this.height - y_right(d.y);
                            }
                            return y(0) - y(d.y)
                        })
                        // .attr("width", x.bandwidth()); // Si tu escala X es de tipo band, esto te dará el ancho de las bandas.
                        .attr("width", barWidth);
                }

                focus.push(this.svg
                    .append('g')
                    .append('circle')
                    .style("fill", color)
                    .attr("stroke", color)
                    .attr('r', 3)
                    .style("opacity", 0)
                );
                    
            });

            
            

            // var focusText = this.svg
            //     .append('g')
            //     .append('text')
            //     .style("opacity", 0)
            //     .style("fill", "white")
            //     .attr("text-anchor", "left")
            //     .attr("alignment-baseline", "middle")

            var focusText = d3.select("body").append("div")   
                .attr("class", "tooltip")               
                .style("opacity", 0);

            var xFocusLine = this.svg.append('line')
                .style("stroke", "grey")
                .style("stroke-dasharray", ("3, 3"))  // Dashed line
                .style("opacity", 0)
            var yFocusLine = this.svg.append('line')
                .style("stroke", "grey")
                .style("stroke-dasharray", ("3, 3"))  // Dashed line
                .style("opacity", 0)

            var xFocusBox = this.svg.append('rect')
                .attr('width', 70)
                .attr('height', 30)
                .style("fill", "#000")
                .style("opacity", 0)
                .attr('rx', 8)  // redondeo horizontal
                .attr('ry', 8); // redondeo vertical

            var yFocusBox = this.svg.append('rect')
                .attr('width', 60)
                .attr('height', 30)
                .style("fill", "#000")
                .style("opacity", 0)
                .attr('rx', 8)  // redondeo horizontal
                .attr('ry', 8); // redondeo vertical

            var xFocusText = this.svg.append('text')
                .style("opacity", 0)
                .style("font-size", 13)
                .style("fill", "white")
                .attr("text-anchor", "middle")
                .attr("alignment-baseline", "middle");
            var yFocusText = this.svg.append('text')
                .style("opacity", 0)
                .style("fill", "white")
                .style("font-size", 13)
                .attr("text-anchor", "middle")
                .attr("alignment-baseline", "middle");

            var rect = this.svg
                .append('rect')
                .style("fill", "none")
                .style("pointer-events", "all")
                .attr('width', this.width)
                .attr('height', this.height)
                .on('mouseover', () => this.mouseover(focus, focusText, xFocusLine, yFocusLine, xFocusBox, xFocusText, yFocusBox, yFocusText))
                .on('mousemove', () => this.mousemove(bisect, data, x, y, y_right, focus, focusText, xFocusLine, yFocusLine, xFocusBox, xFocusText, yFocusBox, yFocusText, x_extent))
                .on('mouseout', () => this.mouseout(focus, focusText, xFocusLine, yFocusLine, xFocusBox, xFocusText, yFocusBox, yFocusText));
        },
        mouseover(focus, focusText, xFocusLine, yFocusLine, xFocusBox, xFocusText, yFocusBox, yFocusText) {
            // focus.style("opacity", 1);
            focusText.style("opacity", 1);
            xFocusLine.style("opacity", 1);
            yFocusLine.style("opacity", 1);
            xFocusBox.style("opacity", 0.85);
            xFocusText.style("opacity", 1);
            yFocusBox.style("opacity", 0.85);
            yFocusText.style("opacity", 1);
        },
        // mousemove(bisect, data, x, y, focus, focusText, xFocusLine, yFocusLine, xFocusBox, xFocusText, yFocusBox, yFocusText) {
        //     var x0 = x.invert(d3.pointer(event, this.svg.node())[0]);
        //     var i = bisect(data, x0, 1);
        //     var selectedData = data[i];
        //     focus
        //         .attr("cx", x(selectedData.x))
        //         .attr("cy", y(selectedData.y));
        //     focusText
        //         .html(selectedData.y)
        //         .attr("x", x(selectedData.x) + 15)
        //         .attr("y", y(selectedData.y));

        //     xFocusLine
        //         .attr("x1", x(selectedData.x))
        //         .attr("y1", y(selectedData.y))
        //         .attr("x2", x(selectedData.x))
        //         .attr("y2", this.height);
        //     yFocusLine
        //         .attr("x1", x(selectedData.x))
        //         .attr("y1", y(selectedData.y))
        //         .attr("x2", 0)
        //         .attr("y2", y(selectedData.y));
        mousemove(bisect, data, x, y, y_right, focus, focusText, xFocusLine, yFocusLine, xFocusBox, xFocusText, yFocusBox, yFocusText, xExtent) {
            var x0 = x.invert(d3.pointer(event, this.svg.node())[0]);
            var y0 = d3.pointer(event, this.svg.node())[1]; // obtiene la posición y del ratón
            var i = bisect(data[0].values, x0, 1);
            var selectedData = data[0].values[i];

            // Lineas discontinuas horizontales y verticales
            xFocusLine
                .attr("x1", x(x0))
                .attr("y1", 0)
                .attr("x2", x(x0))
                .attr("y2", this.height);
            yFocusLine
                .attr("x1", 0)
                .attr("y1", y0)
                .attr("x2", this.width)
                .attr("y2", y0);

            // Círculo y texto para la curva y si existe valor
            data.forEach((dataset, idx) => {
                var iIt = bisect(data[idx].values, x0, 1);
                var selectedDataIt = data[idx].values[iIt];
                if (selectedDataIt === undefined) return;
                if(selectedDataIt.y !== null && selectedDataIt.y !== undefined && this.datasets_info[idx].visible) {
                    focus[idx]
                        .attr("cx", x(selectedDataIt.x))
                        .attr("cy", !dataset.yAxisRight ? y(selectedDataIt.y) : y_right(selectedDataIt.y))
                        .style("opacity", 1); // muestra el círculo si existe valor
                    this.datasets_info[idx].current_value = selectedDataIt.y;

                } else {
                    focus[idx].style("opacity", 0); // oculta el círculo si no existe valor
                    this.datasets_info[idx].current_value = null;
                }
            });

            // focusText
            //     // .html(selectedData.y)
            //     .html(document.getElementById("plantilla").innerHTML)
            //     .attr("x", x(selectedData.x) + 15)
            //     .attr("y", 50)
            //     .style("opacity", 1); // muestra el texto si existe valor
            // focusText
            //     .html(document.getElementById("plantilla").innerHTML)
            //     .attr("x", x(selectedData.x) + 15 )     
            //     .attr("y", 50)
            //     .style("opacity", 1); // muestra el texto si existe valor
            document.getElementById(`tooltip-${this.id}`).style.opacity = 1;
            document.getElementById(`tooltip-${this.id}`).style.left = x(x0) > this.width - 250 ? (x(x0)-200)+"px" : (x(x0)+70)+"px";
            document.getElementById(`tooltip-${this.id}`).style.top = 120 + "px";

            xFocusBox
                .attr("x", x(x0) - 35)
                .attr("y", this.height);
            xFocusText
                // .html(d3.timeFormat("%d %b")(selectedData.x)) // Asegúrate de que la fecha se muestre en el formato correcto
                .html(formatTime("%d %b")(x0).replace(/(^0+)/, "")) // Asegúrate de que la fecha se muestre en el formato correcto
                .attr("x", x(x0) - 0)
                .attr("y", this.height + 15);

            yFocusBox
                .attr("x", -60)
                .attr("y", y0 - 15);
            yFocusText
                .html(y.invert(y0).toFixed(2))
                .attr("x", -32)
                .attr("y", y0 + 0);
        },
        mouseout(focus, focusText, xFocusLine, yFocusLine, xFocusBox, xFocusText, yFocusBox, yFocusText) {
            // console.log("mouseout");
            focus.forEach(element => {
                element.style("opacity", 0);
            });
            focusText.style("opacity", 0);
            xFocusLine.style("opacity", 0);
            yFocusLine.style("opacity", 0);
            xFocusBox.style("opacity", 0);
            xFocusText.style("opacity", 0);
            yFocusBox.style("opacity", 0);
            yFocusText.style("opacity", 0);
            document.getElementById(`tooltip-${this.id}`).style.opacity = 0;
        },
    }
}
</script>
<style scoped>
.dataviz {
    position: relative;
    width: 95%;
    height: calc(100% - 100px);
    top: 40px;
}

.legend {
    font-size: 12px;
    font-family: sans-serif;
    fill: #000;
    width: 100%;
    height: 50px;
    top: 50px;
    left: 60px;
}

.plantilla {
    opacity: 0; 
    width: 250px; 
    position: absolute;
    transition: opacity 0.3s;
    background: #000000d0;
    z-index: 100;
}
</style>
