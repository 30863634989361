<template>
    <v-app>
        <DrawerComponent :mini-variant="miniVariant"></DrawerComponent>
        <v-main class="blue-grey lighten-5">
            <router-view/>
        </v-main>
    </v-app>
</template>

<script>
import DrawerComponent from '@/components/DrawerComponent.vue';


export default {
    name: "MainLayout",
    components: { DrawerComponent },
    data: () => ({
        miniVariant: true,
    }),
};
</script>

<style>
    /* Esto aplica a la barra de desplazamiento en todo el documento */
    ::-webkit-scrollbar {
    width: 7px;  /* para barras verticales, establece el ancho */
    height: 7px;  /* para barras horizontales, establece la altura */
    }

    /* Esto es para el fondo de la barra de desplazamiento */
    ::-webkit-scrollbar-track {
    background: #1a1919; 
    }

    /* Esto es para la 'manija' de la barra de desplazamiento */
    ::-webkit-scrollbar-thumb {
    background: #4a5961; 
    }

    /* Cuando pases el ratón sobre la barra de desplazamiento, cambia el color */
    ::-webkit-scrollbar-thumb:hover {
    background: #8f8d8d; 
    }
</style>