<template>
    <v-container fluid class="fill-height blue-grey darken-4 px-3 py-0">
        <v-container fluid class="pa-0 align-self-start">
            <v-app-bar color="blue-grey darken-4" dark app fixed flat>
                <v-app-bar-nav-icon>
                    <template v-slot:default>
                        <v-img :src="require('@/assets/logo_leaf.png')" max-height="35" contain></v-img>
                    </template>
                </v-app-bar-nav-icon>
                <v-toolbar-title>Previsión del tiempo</v-toolbar-title>
                
                <v-spacer></v-spacer>
                <!-- <v-btn class="px-6 py-5 rounded-lg teal darken-2 text-none teal--text text--lighten-4" flat>
                    <v-icon left small>mdi-download</v-icon>
                    <span class="ml-2">Descargar</span>
                </v-btn> -->
            </v-app-bar>
            <v-row class="grey darken-4">
                
                <v-col cols="9" class="px-0 py-3 overflow-y-auto" style="max-height: calc(100vh - 53px);">
                    <v-overlay :value="loading" :absolute="true">
                        <v-progress-circular
                            indeterminate
                            size="64"
                        ></v-progress-circular>
                    </v-overlay>
                    <v-card dark tile width="100%" class="pa-5">
                        <v-row class="pa-2">
                            <v-col cols="3" class="pa-1">
                                <v-card class="teal darken-4 pa-2 pr-0 pl-8" dark>
                                    <v-row class="pt-1">
                                        <v-col cols="5" class="d-flex flex-column px-0">
                                            <span>Ahora</span>
                                            <span class="font-weight-light subtitle-2">{{ current_data.condition?.text }}</span>
                                        </v-col>
                                        <v-col cols="3" class="d-flex align-center px-0">
                                            <span class="text-h4 text-center">{{current_data.temp_c}}º</span>
                                        </v-col>
                                        <v-col cols="4" class="d-flex align-center mt-n3 pt-4 px-0">
                                            <i class="wu wu-white wu-64" :class="weatherIcons[current_data.condition?.code] + (!current_data?.is_day ? ' wu-night' : '')"></i>
                                        </v-col>
                                    </v-row>
                                </v-card>
                            </v-col>
                            <v-col cols="2" class="pa-1">
                                <v-card class="blue-grey darken-4 px-4 pa-2 fill-height d-flex flex-column" dark>
                                    <span class="font-weight-light grey--text text--lighten-1 subtitle-2">Precipitaciones</span>
                                    <span class="text-h6">{{ current_data.precip_mm }} <span class="subtitle-2">mm</span></span>
                                </v-card>
                            </v-col>
                            <v-col cols="2" class="pa-1">
                                <v-card class="blue-grey darken-4 px-4 pa-2 fill-height d-flex flex-column" dark>
                                    <span class="font-weight-light grey--text text--lighten-1 subtitle-2">Humedad relativa</span>
                                    <span class="text-h6">{{ current_data.humidity }} <span class="subtitle-2">%</span></span>
                                </v-card>
                            </v-col>
                            <v-col cols="1" class="pa-1">
                                <v-card class="blue-grey darken-4 px-4 pa-2 fill-height d-flex flex-column" dark>
                                    <span class="font-weight-light grey--text text--lighten-1 subtitle-2">Nubes</span>
                                    <span class="text-h6">{{ current_data.cloud }} <span class="subtitle-2">%</span></span>
                                </v-card>
                            </v-col>
                            <v-col cols="2" class="pa-1">
                                <v-card class="blue-grey darken-4 px-4 pa-2 fill-height d-flex flex-column" dark>
                                    <span class="font-weight-light grey--text text--lighten-1 subtitle-2">Viento</span>
                                    <span class="text-h6">
                                        {{ current_data.wind_kph }} 
                                        <span class="subtitle-2">km/h <v-icon right color="grey" class="mt-n2" :style="'transform: rotate('+current_data.wind_degree+'deg);'">mdi-navigation</v-icon></span>
                                    </span>
                                </v-card>
                            </v-col>
                            <v-col cols="2" class="pa-1">
                                <v-card class="blue-grey darken-4 px-4 pa-2 fill-height d-flex flex-column" dark>
                                    <span class="font-weight-light grey--text text--lighten-1 subtitle-2">Evapotranspiración</span>
                                    <span class="text-h6">{{ forecast_data[0]?.day.eto }}  <span class="subtitle-2">mm</span></span>
                                </v-card>
                            </v-col>
                        </v-row>
                    </v-card>
                    <!-- <template v-for="day in forecast_data"> -->
                    <v-card dark outlined tile width="100%" height="70" class="forecast d-flex justify-start align-center pa-2" v-for="item in forecast_data" :key="item.date_epoch">
                        <span class="pa-4 mt-2">
                            <i class="wu wu-white wu-32" :class="weatherIcons[item.day.condition.code]"></i>
                            <!-- <img :src="getIcon(item.weather[0].icon)" alt="weather icon" /> -->
                            <!-- <img :src="require(`@/assets/weatherIcons/64x64/${weatherIcons[item.weather[0].icon]}`)" alt="weather icon" /> -->
                        </span>
                        <span class="d-flex flex-column mx-4">
                            <span class="caption blue-grey--text text--lighten-3">{{ getInfoFromTimestamp(item.date_epoch).day_of_week }}</span>
                            <span>{{ getInfoFromTimestamp(item.date_epoch).day }} de {{ getInfoFromTimestamp(item.date_epoch).month }}</span>
                        </span>
                        <!-- <span class="d-flex flex-column mx-4"> -->
                            <!-- <span class="caption blue-grey--text text--lighten-2">Jueves</span> -->
                            <!-- <span class="font-weight-light">{{ getInfoFromTimestamp(item.date_epoch).hour }}:{{ getInfoFromTimestamp(item.date_epoch).minute }}</span>
                        </span> -->
                        <span class="d-flex flex-column mx-4">
                            <span class="caption blue-grey--text text--lighten-3">Máx / Mín</span>
                            <span class="caption">{{ item.day.maxtemp_c }} º / {{ item.day.mintemp_c }} º</span>
                        </span>
                        <span class="d-flex flex-column mx-4">
                            <span class="caption blue-grey--text text--lighten-3">Precipitaciones</span>
                            <span class="caption">{{ item.day.totalprecip_mm }} mm</span>
                        </span>
                        <span class="d-flex flex-column mx-4">
                            <span class="caption blue-grey--text text--lighten-3">Humedad relativa</span>
                            <span class="caption">{{ item.day.avghumidity }} %</span>
                        </span>
                        <span class="d-flex flex-column mx-4">
                            <span class="caption blue-grey--text text--lighten-3">Nubes</span>
                            <span class="caption">{{ item.day.avgcloud }} %</span>
                        </span>
                        <span class="d-flex flex-column mx-4">
                            <span class="caption blue-grey--text text--lighten-3">Viento</span>
                            <span class="caption">{{ (item.day.avgwind_kph)?.toFixed(1) }} km/h 
                                <v-icon small color="grey" :style="'transform: rotate('+getItemWindDegree(item)+'deg);'">mdi-navigation</v-icon>
                            </span>
                        </span>
                        <span class="d-flex flex-column mx-4">
                            <span class="caption blue-grey--text text--lighten-3">Evapotranspiración</span>
                            <span class="caption">{{ item.day.eto }} mm</span>
                        </span>
                        <!-- <span class="d-flex flex-column mx-4">
                            <span class="caption blue-grey--text text--lighten-3">Máx / Mín</span>
                            <span class="caption">28 º / 12 º</span>
                        </span> -->
                    </v-card>
                </v-col>
                <v-col cols="3" class="px-0 pt-3 pb-0 fill-height overflow-y-auto" style="max-height: calc(100vh - 53px);">
                    <SectorsNavigationComponent v-model="selectedSector" :farms="farms" :navigation-mode="false"/>
                </v-col>
            </v-row>
            <!-- </template> -->
        </v-container>
    </v-container>
</template>

<script>
import SectorsNavigationComponent from '@/components/SectorsNavigationComponent.vue'
import { mapGetters, mapActions } from 'vuex'
import api from '@/services/api'
import '@johnmmackey/weather-underground-icons/dist/wu-icons-style.min.css'

Date.prototype.getDayOfYear = function () {
    var start = new Date(this.getFullYear(), 0, 0);
    var diff = this - start;
    var oneDay = 1000 * 60 * 60 * 24;
    return Math.floor(diff / oneDay);
}

export default {
    name: 'ForecastView',
    components: {
        SectorsNavigationComponent
    },
    props: {
        sectorId: {
            type: String,
            default: null,
        },
    },
    data() {
        return {
            loading: false,
            selectedSector: null,
            forecast_data: [],
            current_data: [],
        };
    },
    computed: {
        ...mapGetters(['farms', 'weatherIcons']),
    },
    watch: {
        selectedSector: {
            handler: function (val, oldVal) {
                if (val) {
                    this.getWeatherPrediction()
                }
            },
            deep: true
        },
        farms: {
            handler: function (val, oldVal) {
                if (val) {
                    this.assignSelectedSector()
                }
            },
            deep: true
        }   
    },
    methods: {
        ...mapActions(['getFarms']),
        getWeatherPrediction() {
            this.loading = true
            api.get(`/forecast/${this.selectedSector.id}`)
            .then(response => {
                this.forecast_data = response.data.data.forecast.forecastday;
                this.current_data = response.data.data.current;
                this.loading = false;
            })
        },
        getItemWindDegree(item) {
            const hour = item.hour[0]
            const windDegree = hour ? hour.wind_degree : 0
            return windDegree
        },
        getInfoFromTimestamp(timestamp) {
            const str_months = [
                'Enero',
                'Febrero',
                'Marzo',
                'Abril',
                'Mayo',
                'Junio',
                'Julio',
                'Agosto',
                'Septiembre',
                'Octubre',
                'Noviembre',
                'Diciembre'
            ];
            const str_days_of_week = [
                'Domingo',
                'Lunes',
                'Martes',
                'Miércoles',
                'Jueves',
                'Viernes',
                'Sábado'
            ];
            const date = new Date(timestamp * 1000)
            const month = date.getMonth()
            const str_month = str_months[month]
            const str_day_of_week = str_days_of_week[date.getDay()]
            const day_of_month = date.getDate()
            const hour = date.getHours() < 10 ? '0' + date.getHours() : date.getHours()
            const minute = date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes()

            return {
                month: str_month,
                day_of_week: str_day_of_week,
                day: day_of_month,
                hour: hour,
                minute: minute,
            }
        },
        assignSelectedSector() {
            if (this.sectorId) {
                this.selectedSector = this.farms.flatMap(farm => farm.sectors).find(sector => sector.id == parseInt(this.sectorId))
            } else {
                this.selectedSector = this.farms.flatMap(farm => farm.sectors)[0]
            }
        }
    },
    mounted() {
        if (this.farms.length == 0) {
            this.getFarms();
        } else {
            this.assignSelectedSector()
        }
    },
};
</script>

<style scoped>
.forecast>span {
    min-width: 100px;
}
.forecast>span:first-child {
    min-width: 0 !important;
}
</style>    
  