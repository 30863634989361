<template>
    <v-container fluid class="fill-height blue-grey darken-4  px-3 py-0">
        <v-container fluid class="pa-0 align-self-start">
            <v-app-bar color="blue-grey darken-4" dark app fixed flat>
                <v-app-bar-nav-icon>
                    <template v-slot:default>
                        <v-img :src="require('@/assets/logo_leaf.png')" max-height="35" contain></v-img>
                    </template>
                </v-app-bar-nav-icon>
                <v-toolbar-title>Análisis</v-toolbar-title>
                <div class="no-gutters d-flex justify-start ml-10" style="width: 700px">
                    <v-menu
                        ref="fromDate"
                        v-model="menu"
                        :close-on-content-click="true"
                        :return-value.sync="fromDate"
                        transition="scale-transition"
                        offset-y
                        min-width="auto"
                        dark
                    >
                        <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                            v-model="fromDate"
                            label="Fecha inicio"
                            v-bind="attrs"
                            v-on="on"
                            readonly
                            filled 
                            hide-details 
                            rounded 
                            dense 
                            class="mr-3 rounded-lg blue-grey darken-4" 
                            :menu-props="{ offsetY: true, maxHeight: '300px' }"
                            prepend-inner-icon="mdi-calendar-month"
                        ></v-text-field>
                        </template>
                        <v-date-picker
                        v-model="fromDate"
                        no-title
                        scrollable
                        color="teal lighten-2"
                        locale="es"
                        >
                        </v-date-picker>
                    </v-menu>
                    <v-menu
                        ref="toDate"
                        v-model="menu2"
                        :close-on-content-click="true"
                        transition="scale-transition"
                        offset-y
                        min-width="auto"
                        dark
                    >
                        <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                            v-model="toDate"
                            label="Fecha fin"
                            v-bind="attrs"
                            v-on="on"
                            readonly
                            filled 
                            hide-details 
                            rounded 
                            dense 
                            class="mr-3 rounded-lg blue-grey darken-4" 
                            :menu-props="{ offsetY: true, maxHeight: '300px' }"
                            prepend-inner-icon="mdi-calendar-month"
                        ></v-text-field>
                        </template>
                        <v-date-picker
                        v-model="toDate"
                        no-title
                        scrollable
                        color="teal lighten-2"
                        locale="es"
                        >
                        </v-date-picker>
                    </v-menu>
                </div>
                <v-spacer></v-spacer>
                <!-- <v-btn class="px-6 py-5 rounded-lg teal darken-2 text-none teal--text text--lighten-4" flat>
                    <v-icon left small>mdi-download</v-icon>
                    <span class="ml-2">Descargar</span>
                </v-btn> -->
            </v-app-bar>
            <v-row class="grey darken-4">
                <v-col cols="9" class="px-0 py-3 overflow-y-auto overflow-x-hidden" style="max-height: calc(100vh - 53px);">
                    <v-overlay :value="loading" :absolute="true">
                        <v-progress-circular
                            indeterminate
                            size="64"
                        ></v-progress-circular>
                    </v-overlay>
                    <v-card dark outlined tile width="100%" height="400" class="px-0 fill-height" v-for="item in [0, 1, 2, 3, 4, 5, 6]" :key="item">
                        <D3AreaChart :data="chartData[`serie${item}`].values" :title="chartData[`serie${item}`].name" :description="chartData[`serie${item}`].description" :id="'chart-'+item"/>
                    </v-card>
                </v-col>
                <v-col cols="3" class="px-0 pt-3 pb-0 fill-height overflow-y-auto" style="max-height: calc(100vh - 53px);">
                    <SectorsNavigationComponent v-model="selectedSector" :farms="farms" :navigation-mode="false"/>
                </v-col>
            </v-row>
        </v-container>
    </v-container>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import SectorsNavigationComponent from '@/components/SectorsNavigationComponent.vue'
import D3AreaChart from '@/components/D3AreaChart.vue'
import api from '@/services/api'

Date.prototype.getDayOfYear = function () {
    var start = new Date(this.getFullYear(), 0, 0);
    var diff = this - start;
    var oneDay = 1000 * 60 * 60 * 24;
    return Math.floor(diff / oneDay);
}

export default {
    name: 'AnalysisView',
    components: {
        D3AreaChart, SectorsNavigationComponent
    },
    data() {
        return {
            loading: false,
            menu: false,
            menu2: false,
            selectedSector: null,
            fromDate: new Date(new Date().getFullYear(), 0, 2).toISOString().substr(0, 10),
            toDate: new Date().toISOString().substr(0, 10),
            history_data: [],
            chartData: {
                serie0: {
                    name: 'Balance hídrico del terreno, mm',
                    values: [],
                    description: `    
                    <p>Esta gráfica muestra los parámetros clave para el balance hídrico del sector seleccionado.</p>
                        <ul>
                            <li><strong>Capacidad del Campo</strong>: Es la máxima cantidad de agua que el suelo puede retener, medida en milímetros de agua (mm).</li>
                            <li><strong>Estrés Hídrico</strong>: Es la cantidad de agua por debajo de la cual las plantas empiezan a sufrir de estrés hídrico, también medido en mm.</li>
                            <li><strong>Balance Hídrico</strong>: Es la cantidad de agua actual en el suelo, teniendo en cuenta la precipitación, la evapotranspiración y el riego. Este valor se mide en mm.</li>
                            <li><strong>Riego Recomendado</strong>: Este valor pronostica cuándo sería recomendable regar en el futuro y cuánta agua debería usarse, en mm.</li>
                            <li><strong>Plan de Riego</strong>: Muestra los riegos que se han realizado en el pasado, indicando cuánta agua se ha aplicado en cada riego, en mm.</li>
                        </ul>
                        <br/>
                    <p>Deslice el cursor sobre la gráfica para ver estos parámetros para una fecha específica.</p>
                    `
                },
                serie1: {
                    name: 'Índice NDVI',
                    values: [],
                    description: `
                    <p>Esta gráfica muestra el Índice de Vegetación de Diferencia Normalizada (NDVI) para el sector seleccionado.</p>
                    <p>El NDVI es un índice que mide la cantidad de vegetación en un área determinada. El NDVI se calcula a partir de la reflectancia de la luz en el infrarrojo cercano y en el rojo, y se calcula como:</p>
                    <p style="font-weight: bold;">NDVI = (NIR - RED)/(NIR + RED)</p>
                    <p>El NDVI varía entre -1 y 1, siendo los valores más altos indicativos de una mayor cantidad de vegetación.</p>
                    <p>El índice de nubosidad (CLP) se calcula como:</p>
                    <p style="font-weight: bold;">CLP = (NIR + RED)/2</p>
                    <p>El CLP varía entre 0 y 1, siendo los valores más altos indicativos de una mayor cantidad de nubes, lo que puede afectar negativamente a la precisión del NDVI.</p>
                    <p>Deslice el cursor sobre la gráfica para ver el valor del NDVI para una fecha específica.</p>
                    `
                },
                serie2: {
                    name: 'Precipitaciones diarias, mm',
                    values: [],
                    description: `
                    <p>Esta gráfica muestra las precipitaciones diarias en el sector seleccionado.</p>
                    <ul>
                        <li><strong>Precipitaciones diarias</strong>: Registra la cantidad de lluvia caída cada día en el sector. Los valores se miden en milímetros (mm).</li>
                    </ul><br/>
                    <p>Deslice el cursor sobre la gráfica para ver la cantidad de precipitaciones de un día específico.</p>
                    `
                },
                serie3: {
                    name: 'Evapotranspiración, mm',
                    values: [],
                    description: `
                    <p>Esta gráfica muestra la evapotranspiración diaria en el sector seleccionado.</p>
                    <ul>
                        <li><strong>Evapotranspiración diaria (ETo)</strong>: Representa la suma de la evaporación y la transpiración de la vegetación en el sector. Este valor se mide en milímetros (mm) y es una indicación de la cantidad de agua que se pierde al ambiente por evaporación desde el suelo y transpiración de las plantas. ETo es una variable clave en la gestión del agua, ya que ayuda a determinar cuánto riego es necesario para compensar la pérdida de agua.</li>
                    </ul>
                    <br/>
                    <p>Deslice el cursor sobre la gráfica para ver la evapotranspiración de un día específico.</p>
                    `
                },
                serie4: {
                    name: 'Temperaturas diarias, ºC',
                    values: [],
                    description: `
                    <p>Esta gráfica muestra las temperaturas diarias en el sector seleccionado.</p>
                    <ul>
                    <li><strong>Temperatura Máxima</strong>: Esta es la temperatura más alta registrada en el día. Es un factor importante que puede afectar la tasa de evapotranspiración y el crecimiento de las plantas.</li>
                    <li><strong>Temperatura Mínima</strong>: Esta es la temperatura más baja registrada en el día. Las temperaturas más bajas pueden ralentizar el crecimiento de las plantas y reducir la evapotranspiración.</li>
                    <li><strong>Temperatura Media</strong>: Esta es la media de la temperatura máxima y mínima. Proporciona una visión general del clima del día.</li>
                    </ul><br/>
                    <p>Deslice el cursor sobre la gráfica para ver las temperaturas de un día específico.</p>
                    `
                },
                serie5: {
                    name: 'Humedad relativa, %',
                    values: [],
                    description: `
                    <p>Esta gráfica muestra la humedad relativa media diaria en el sector seleccionado.</p>
                    <ul>
                        <li><strong>Humedad Relativa</strong>: Es el promedio de la humedad relativa del aire durante el día, medido en porcentaje (%). La humedad relativa puede influir en la tasa de evapotranspiración, y un valor alto puede indicar una menor necesidad de riego.</li>
                    </ul><br/>
                    <p>Deslice el cursor sobre la gráfica para ver la humedad relativa de un día específico.</p>
                    `
                },
                serie6: {
                    name: 'Viento, km/h',
                    values: [],
                    description: `
                    <p>Esta gráfica muestra dos parámetros clave del viento en el sector seleccionado.</p>
                    <ul>
                        <li><strong>Velocidad del viento</strong>: Es el promedio de velocidad del viento durante el día, medido en metros por segundo (m/s). Un viento más fuerte puede aumentar la evapotranspiración y afectar a las plantas.</li>
                        <li><strong>Ráfaga máxima de viento</strong>: Es la velocidad más alta que el viento alcanzó en el día, medido en m/s. Las ráfagas de viento fuertes pueden dañar las plantas y aumentar la evapotranspiración.</li>
                    </ul><br/>
                    <p>Deslice el cursor sobre la gráfica para ver los datos de viento de un día específico.</p>
                    `
                }
            },
        };
    },
    computed: {
        ...mapGetters(['farms']),
    },
    watch: {
        selectedSector: {
            handler: function (val, oldVal) {
                if (val) {
                    this.loadData()
                }
            },
            deep: true
        },
        farms: {
            handler: function (val, oldVal) {
                if (val) {
                    this.selectedSector = this.farms[0].sectors[0]
                }
            },
            deep: true
        },
        toDate: {
            handler: function (val, oldVal) {
                if (val) {
                    this.loadData()
                }
            },
            deep: true
        },
        fromDate: {
            handler: function (val, oldVal) {
                if (val) {
                    this.loadData()
                }
            },
            deep: true
        },
    },
    methods: {
        ...mapActions(['getFarms']),
        async loadData () {
            this.loading = true
            await api.get(`/history/${this.selectedSector.id}/${this.fromDate}/${this.toDate}`)
            .then(response => {
                // console.log(response.data);
                this.history_data = response.data.data.forecast.forecastday;

                this.chartData.serie2.name = 'Precipitaciones diarias, mm',
                this.chartData.serie2.values = [
                    {
                        name: 'Precipitaciones (mm)',
                        values: this.history_data.map(item => {
                            return {
                                x: new Date(item.date),
                                y: item.day.totalprecip_mm,
                            }
                        }),
                        type: "bar",
                        color: "#00bcd4",
                    },
                ]

                this.chartData.serie3.name = 'Evapotranspiración, mm',
                this.chartData.serie3.values = [
                    {
                        name: 'Evapotranspiración (mm)',
                        values: this.history_data.map(item => {
                            return {
                                x: new Date(item.date),
                                y: item.day.eto,
                            }
                        }),
                        type: "line",
                        color: "#61E37B"
                    },
                ]

                this.chartData.serie4.name = 'Temperaturas diarias, ºC',
                this.chartData.serie4.values = [
                    {
                        name: 'Temperatura máxima (ºC)',
                        values: this.history_data.map(item => {
                            return {
                                x: new Date(item.date),
                                y: item.day.maxtemp_c,
                            }
                        }),
                        type: "line",
                        color: "#ff5722"
                    },
                    {
                        name: 'Temperatura mínima (ºC)',
                        values: this.history_data.map(item => {
                            return {
                                x: new Date(item.date),
                                y: item.day.mintemp_c,
                            }
                        }),
                        type: "line",
                        color: "#61E37B"
                    },
                    {
                        name: 'Temperatura media (ºC)',
                        values: this.history_data.map(item => {
                            return {
                                x: new Date(item.date),
                                y: item.day.avgtemp_c,
                            }
                        }),
                        type: "line",
                    },
                ]
                
                this.chartData.serie5.name = 'Humedad relativa, %',
                this.chartData.serie5.values = [
                    {
                        name: 'Humedad relativa (%)',
                        values: this.history_data.map(item => {
                            return {
                                x: new Date(item.date),
                                y: item.day.avghumidity,
                            }
                        }),
                        type: "line",
                        yAxis: [0, 100],
                    },
                ]

                this.chartData.serie6.name = 'Viento, m/s',
                this.chartData.serie6.values = [
                    {
                        name: 'Velocidad del viento (km/h)',
                        values: this.history_data.map(item => {
                            return {
                                x: new Date(item.date),
                                y: item.day.avgwind_kph * 0.75,
                            }
                        }),
                        type: "line",
                    },
                    {
                        name: 'Ráfaga máxima de viento (km/h)',
                        values: this.history_data.map(item => {
                            return {
                                x: new Date(item.date),
                                y: item.day.maxwind_kph,
                            }
                        }),
                        type: "line",
                    },
                ]
                

            })

            await api.get(`/indices/${this.selectedSector.id}/${this.fromDate}/${this.toDate}`)
            .then(response => {
                // console.log(response.data);
                const clpFilter = 0.5
                this.chartData.serie1.name = 'Índice NDVI',
                this.chartData.serie1.values = [
                    {
                        name: 'NDVI',
                        values: response.data.indices.filter(
                            item => item.clp < clpFilter
                        )
                        .map(item => {
                            return {
                                x: new Date(item.date),
                                y: item.ndvi,
                            }
                        }),
                        type: "line",
                        yAxis: [0, 1],
                    },
                    {
                        name: 'Índice de nubosidad',
                        values: response.data.indices.filter(
                            item => item.clp < clpFilter
                        )
                        .map(item => {
                            return {
                                x: new Date(item.date),
                                y: item.clp,
                            }
                        }),
                        type: "line",
                    },
                ]
            })

            await api.get(`/waterBalance/${this.selectedSector.id}`)
            .then(response => {
                const saturationLevel = response.data.balanceData[0]?.saturationLevel
                const fieldCapacity = response.data.balanceData[0]?.fieldCapacity
                const wiltingPoint = response.data.balanceData[0]?.wiltingPoint
                console.log(saturationLevel, wiltingPoint);

                this.chartData.serie0.name = 'Balance hídrico del terreno, mm',
                this.chartData.serie0.values = [
                    {
                        name: 'Capacidad del campo',
                        values: response.data.balanceData.map(item => {
                            return {
                                x: new Date(item.date),
                                y: item.fieldCapacity - wiltingPoint ,
                            }
                        }),
                        type: "line",
                        yAxis: [wiltingPoint, fieldCapacity - wiltingPoint],
                        color: "#0a4b75",
                        isForecast: true,
                    },
                    {
                        name: 'Estrés hídrico',
                        values: response.data.balanceData.map(item => {
                            return {
                                x: new Date(item.date),
                                y: item.estressLevel - wiltingPoint,
                            }
                        }),
                        type: "line",
                        color: "#fb6f24"
                    },
                    // {
                    //     name: 'Punto de marchitez permanente (mm)',
                    //     values: response.data.balanceData.map(item => {
                    //         return {
                    //             x: new Date(item.date),
                    //             y: item.wiltingPoint - wiltingPoint,
                    //         }
                    //     }),
                    //     type: "line",
                    // },
                    {
                        name: 'Precipitaciones',
                        values: response.data.balanceData.map(item => {
                            return {
                                x: new Date(item.date),
                                y: item.totalprecip_mm,
                            }
                        }),
                        type: "bar",
                        color: "#5191c1",
                    },
                    {
                        name: 'Balance hídrico',
                        values: response.data.balanceData.map(item => {
                            return {
                                x: new Date(item.date),
                                y: (item.waterBalance - wiltingPoint) > 0 ? (item.waterBalance > fieldCapacity ? fieldCapacity - wiltingPoint : item.waterBalance - wiltingPoint) : 0,
                            }
                        }),
                        type: "line",
                        color: "#00bcd4"
                    },
                    {
                        name: 'Riego recomendado',
                        values: response.data.balanceData.map(item => {
                            return {
                                x: new Date(item.date),
                                y: item.irrigationAdvice,
                            }
                        }),
                        type: "bar",
                        color: "#61E37B"
                    },
                    {
                        name: 'Plan de riego',
                        values: response.data.balanceData.map(item => {
                            return {
                                x: new Date(item.date),
                                y: item.irrigation,
                            }
                        }),
                        type: "bar",
                        color: "#067f33"
                    },
                ]
            })
            this.loading = false
        },
    },
    created() {
        if (this.farms.length == 0) {
            this.getFarms();
        } else {
            this.selectedSector = this.farms[0].sectors[0];
        }
    },
};
</script>

<style scoped>

</style>    
  