import api from '@/services/api'

export default {
    namespaced: true,
    state: {
        token: localStorage.getItem('token') || localStorage.getItem('token'),
        status: '',
        user: {},
    },
    getters: {
        isLoggedIn: state => !!state.token,
        authStatus: state => state.status,
    },
    mutations: {
        authRequest(state) {
            state.status = 'loading'
        },
        authSuccess(state, token, user) {
            state.status = 'success'
            state.token = token
            state.user = user
        },
        authError(state) {
            state.status = 'error'
        },  
        logout(state) {
            state.status = ''
            state.token = ''
        },
    },
    actions: {
        login({ commit }, user) {
            // return new Promise((resolve, reject) => {
            //     commit('authRequest')
                api.post('/login', user)
                .then(resp => {
                    const token = resp.data.token
                    const user = resp.data.user
                    console.log(resp.data)
                    localStorage.setItem('token', token)
                    api.defaults.headers.common['Authorization'] = token
                    commit('authSuccess', token, user)
                    // this.dispatch('goHome')
                    resolve(resp)
                })
                .catch(err => {
                    commit('authError')
                    // localStorage.removeItem('token')
                    // reject(err)
                })
            // }).catch(err => {
            //     console.error('Error during authentication:', err);
            // })
        },
        logout({ commit }) {
            return new Promise((resolve, reject) => {
                commit('logout')
                localStorage.removeItem('token')
                delete api.defaults.headers.common['Authorization']
                resolve()
            })
        },
    }
}