import api from '@/services/api'

export default {
    namespaced: false,
    actions: {
        insertSector({ commit, dispatch }, sector) {
            api
            .put(`/sectors`, { sector })
            .then(response => {
                if (response.data.success) {
                    // commit('setFarms', response.data.farms)
                    dispatch('getFarms')
                } else if (response.status === 401 || response.status === 403) {
                    // console.log(response.data.farms)
                    // dispatch('goToLogin')
                }
            })
            .catch(function (error) {
                console.log(error)
                // dispatch('getFarms')
            })
        },
        updateSector({ commit, dispatch }, sector) {
            api
            .post(`/sectors`, { sector })
            .then(response => {
                if (response.data.success) {
                    // commit('setFarms', response.data.farms)
                    dispatch('getFarms')
                }
            })
            .catch(function (error) {
                console.log(error)
                // dispatch('getFarms')
            })
        },
        deleteSector({ commit, dispatch }, { sectorId, farmId }) {
            api
            .delete(`/sectors/${sectorId}/${farmId}`)
            .then(response => {
                if (response.data.success) {
                    // commit('setFarms', response.data.farms)
                    dispatch('getFarms')
                } else if (response.status === 401 || response.status === 403) {
                    // console.log(response.data.farms)
                    // dispatch('goToLogin')
                }
            })
            .catch(function (error) {
                console.log(error)
                // dispatch('getFarms')
            })
        },
    },
};