import api from '@/services/api'

export default {
    namespaced: false,
    actions: {
        insertEvent({ commit, dispatch }, event) {
            api
            .put(`/events`, { event })
            .then(response => {
                if (response.data.success) {
                    // commit('setFarms', response.data.farms)
                    dispatch('getFarms')
                } else if (response.status === 401 || response.status === 403) {
                    // console.log(response.data.farms)
                    // dispatch('goToLogin')
                }
            })
            .catch(function (error) {
                console.log(error)
                // dispatch('getFarms')
            })
        },
        updateEvent({ commit, dispatch }, event) {
            api
            .post(`/events`, { event })
            .then(response => {
                if (response.data.success) {
                    // commit('setFarms', response.data.farms)
                    dispatch('getFarms')
                }
            })
            .catch(function (error) {
                console.log(error)
                // dispatch('getFarms')
            })
        },
        deleteEvent({ commit, dispatch }, { eventId, sectorId }) {
            api
            .delete(`/events/${eventId}/${sectorId}`)
            .then(response => {
                if (response.data.success) {
                    // commit('setFarms', response.data.farms)
                    dispatch('getFarms')
                } else if (response.status === 401 || response.status === 403) {
                    // console.log(response.data.farms)
                    // dispatch('goToLogin')
                }
            })
            .catch(function (error) {
                console.log(error)
                // dispatch('getFarms')
            })
        },
    },
};