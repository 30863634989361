<template>
    <span style="position: relative;">
        <div :id="id" class="leafmap" :style="'height: ' + height"></div>
        <v-autocomplete
            v-if="!editModeComputed"
            class="floating-search rounded-lg"
            v-model="selectedSectorComputed"
            :items="sectors"
            :return-object="true"
            hide-no-data
            hide-details
            label="Buscar"
            flat
            solo
            dark
            append-icon="mdi-magnify"
        >
            <template v-slot:prepend-inner>
                <v-img
                    :src="require('@/assets/logo_leaf.png')"
                    max-height="35"
                    contain
                    class="ml-n3"
                ></v-img>
            </template>
            <template v-slot:selection="{ item, index }">
                <div class="d-flex align-center">
                    <v-icon class="mr-2" color="white">mdi-vector-square</v-icon>
                    <span class="white--text body-2 ml-2">{{ item.name }}</span>
                    <v-spacer></v-spacer>
                    <v-chip x-small label dark color="teal darken-3" class="ml-3">{{ farms.find(f => f.id === item.farmId).name }}</v-chip>
                </div>
            </template>
            <template v-slot:item="{ item, attrs, on, parent }">
                <v-list-item v-bind="attrs" v-on="on" class="grey darken-4" :class="sectors.indexOf(item)==0 ? 'rounded-t-lg' : sectors.indexOf(item)==sectors.length-1 ? 'rounded-b-lg' : ''">
                    {{ parent.$attrs["class"]="" }}
                    <v-list-item-content dark>
                        <div class="d-flex align-center">
                            <v-icon class="mr-2" color="white">mdi-vector-square</v-icon>
                            <span class="white--text body-2 ml-2">{{ item.name }}</span>
                            <v-spacer></v-spacer>
                            <v-chip x-small label dark color="teal darken-3" class="ml-3">{{ farms.find(f => f.id === item.farmId).name }}</v-chip>
                        </div>
                    </v-list-item-content>
                </v-list-item>
            </template>
        </v-autocomplete>
        <v-card v-if="editModeComputed" dark class="floating-search rounded-lg elevation-5">
            <v-card-title>
                <div class="d-flex flex-column">
                    <span>Dibuja el sector</span>
                    <span class="caption">Pulsa la tecla <strong>Escape</strong> para cancelar</span>
                </div>
                <v-spacer></v-spacer>
                <v-btn icon @click="editModeComputed=false"><v-icon>mdi-close</v-icon></v-btn>
            </v-card-title>
            <v-card-text class="pa-1">
                <!-- <v-img :src="require('@assets/cropping-process.gif')" max-height="35" contain class="ml-n3"></v-img> -->
                <v-img :src="require('@/assets/cropping-process.gif')" max-width="400" contain class="pa-0 rounded-b-lg "></v-img>
            </v-card-text>
        </v-card>
        <div class="d-flex flex-column floating-zoom">
            <v-btn dark x-small class="rounded-b-0 elevation-0 pa-2 py-5 rounded-lg" @click="zoomIn"><v-icon>mdi-plus</v-icon></v-btn>
            <v-btn dark x-small class="rounded-t-0 elevation-0 pa-2 py-5 rounded-lg" @click="zoomOut"><v-icon>mdi-minus</v-icon></v-btn>
        </div>
        <div class="d-flex legend">
            <v-menu 
            offset-y
            top
            left
            :close-on-click="true"
            :close-on-content-click="false"
            dark
            v-model="showLegend"
            >
                <template v-slot:activator="{ on, attrs }">
                    <v-btn
                        dark
                        v-bind="attrs"
                        v-on="on"
                        class="rounded-r-0 pa-6 justify-start text-body-2 elevation-0 rounded-lg"
                        width="200px"
                        style="border-right: 1px solid #666"
                    >
                        NDVI 
                    </v-btn>
                    <v-btn
                        dark
                        v-bind="attrs"
                        v-on="on"
                        class="rounded-l-0 py-6 elevation-0 rounded-lg"
                        width="40px"
                        >
                        <v-icon>{{ showLegend ? 'mdi-chevron-down' : 'mdi-chevron-up' }}</v-icon>
                    </v-btn>
                </template>
                <v-list width="265px" dense :style="`max-height: 400px;`">
                    <span v-for="(color, index) in ndviColors" :key="index" class="d-flex align-center grey darken-4">
                        <div :style="{ backgroundColor: color.color, width: '25px', height: '25px' }"></div>
                        <span class="ml-2 font-weight-regular subtitle-2 grey--text text--lighten-4" style="min-width: 70px;">{{ color.ndvi }}</span>
                        <span class="ml-2 font-weight-regular subtitle-2 grey--text text--lighten-1">{{ color.text }}</span>
                    </span>
                </v-list>
            </v-menu>
        </div>
    </span>
</template>

<script>
import { mapGetters } from 'vuex'
import L from 'leaflet'
import 'leaflet/dist/leaflet.css'
import 'leaflet-draw/dist/leaflet.draw.css'
import 'leaflet-draw'

// Se importan librerías para el mapa relativas a la agrupación de marcadores
import 'leaflet.markercluster';
import 'leaflet.markercluster/dist/MarkerCluster.css'
import 'leaflet.markercluster/dist/MarkerCluster.Default.css'

// Importamos el css font awesome
import '@fortawesome/fontawesome-free/css/all.css'

// import Vue from 'vue'

    export default {
        name: 'MapComponent',
        props: {
            id: {
                type: String,
                default: ''
            },
            height: {
                type: String,
                default: '100%'
            },
            coordinates: {
                type: Array,
                default: () => []
            },
            farms: {
                type: Array,
                default: () => []
            },
            editMode: {
                type: Boolean,
                default: false
            },
            images: {
                type: Array,
                default: () => []
            },
            selectedSector: {
                type: Object,
                default: () => null
            }
        },
        data: () => ({
            map: null,
            showLegend: false,
            sectorLayers: [],
            imageLayers: [],
            editableLayers: [],
        }),
        computed: {
            ...mapGetters(['ndviColors']),
            sectors() {
                return this.farms.map(farm => {
                    return farm.sectors.map(sector => {
                        // Eliminar el atributo sectors de farm
                        const { sectors, ...fa } = farm;
                        return {
                            ...sector,
                            farm: fa
                        }
                    })
                }).flat()
            },
            selectedSectorComputed: {
                get() {
                    return this.selectedSector;
                },
                set(val) {
                    this.$emit('selected-sector', val);
                }
            },
            editModeComputed: {
                get() {
                    return this.editMode;
                },
                set(val) {
                    this.$emit('edit-mode', val);
                }
            }
        },
        watch: {
            selectedSectorComputed(val) {
                if (val) {
                    this.$emit('polygonClick', val);
                }
            },
            editModeComputed(val) {
                if (val) {
                    this.enableDraw();
                } else {
                    this.disableDraw();
                }
            },
            images() {
                // Elimina las imágenes existentes.
                this.imageLayers = this.imageLayers.filter(layer => {
                    this.map.removeLayer(layer);
                    return false;
                });

                // Agrega las imágenes nuevas.
                this.sectors.forEach(sector => {
                    const polygon = L.polygon(sector.coordinates);
                    let image = this.images.find(image => image.sectorId === sector.id);
                    if (image) {
                        const imageOverlay = L.imageOverlay(image.url, polygon.getBounds(), {
                            opacity: 1,
                            // errorOverlayUrl,
                            // alt: altText,
                            interactive: true
                        }).addTo(this.map);
                        this.imageLayers.push(imageOverlay);
                    }

                });

            }
        },
        methods: {
            zoomIn() {
                if (this.map) {
                    this.map.zoomIn();
                }
            },
            zoomOut() {
                if (this.map) {
                    this.map.zoomOut();
                }
            },
            enableDraw() {
                this.drawHandler.enable();
            },
            disableDraw() { // Se accede desde fuera para desactivar el modo edición
                this.drawHandler.disable();
            },
            zoomToSector(sector) { // Se accede desde fuera para hacer zoom a un sector
                this.map.fitBounds(sector.coordinates, { paddingBottomRight: [20, 300] });
            },
            removeEditableLayers() {
                console.log('OK')
                this.editableLayers.clearLayers()
                // this.map.clearLayers()
            },
            createMap() {
                this.map = L.map(this.id, {zoomControl: false, attributionControl: false}).setView([39.91479, -1.32669], 18);

                L.tileLayer('https://{s}.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}', {
                    maxZoom: 19,
                    attribution: '&copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>',
                    subdomains: ["server", "services"],
                }).addTo(this.map);
                L.tileLayer('https://{s}.arcgisonline.com/ArcGIS/rest/services/Reference/World_Boundaries_and_Places/MapServer/tile/{z}/{y}/{x}', {
                    maxZoom: 19,
                    subdomains: ["server", "services"],
                }).addTo(this.map);
            },
            addSectorsToMap() {
                // Para sectores en vez de coordenadas
                this.sectors.forEach(sector => {
                    // Buscar en el array this.images la imagen que corresponde al sector
                    const image = this.images.find(image => image.sectorId === sector.id);

                    // Crear polígono
                    let polygon = L.polygon(sector.coordinates, {
                        color: "white",
                        fillColor: "teal",
                        // fillOpacity: 0.5,  // Opacidad de relleno
                        fillOpacity: image !== null ? 0 : 0.5,  // Opacidad de relleno
                        className: "polygon"
                    })
                    .on('click', () => {
                        this.$emit('polygonClick', sector);
                    });

                    if (image) {
                        const imageOverlay = L.imageOverlay(image.url, polygon.getBounds(), {
                            opacity: 1,
                            // errorOverlayUrl,
                            // alt: altText,
                            interactive: true
                        }).addTo(this.map);
                        this.imageLayers.push(imageOverlay);
                    }

                    // Añadir tooltip con la información del sector
                    let tooltipContent = `<h3>${sector.name} <span class="chip grey">${sector.farm.name}</span></h3>
                    <table>
                        <tr>
                            <td><b>Área</b></td>
                            <td><span class="chip teal">${sector.area} Ha</span></td>
                        </tr>
                        <!-- tr>
                            <td><b>Año cultivo</b></td>
                            <td><span class="chip teal">${sector.year}</span></td>
                        </tr -->
                        <tr>
                            <td><b>Tipo cultivo</b></td>
                            <td><span class="chip teal">${sector.cropType}</span></td>
                        </tr>
                        <tr>
                            <td><b>NDVI</b></td>
                            <td><span class="chip teal">0.12</span></td>
                        </tr>
                    </table>`
        
                    polygon.bindTooltip(tooltipContent);
                    this.sectorLayers.push(polygon);
                    polygon.addTo(this.map);
                });

                const latLngBounds = this.sectors.map(sector => {
                    return sector.coordinates
                })
                this.map.fitBounds(latLngBounds)
            },
            addEditionModeToMap() {
                 // Para dibujar poligonos
                this.editableLayers = new L.FeatureGroup();
                this.map.addLayer(this.editableLayers);

                let drawControl = new L.Control.Draw({
                    edit: {
                        featureGroup: this.editableLayers, // Pasamos la instancia de FeatureGroup aquí
                    }
                });

                // Cambiar los textos de los tooltips a español
                L.drawLocal.draw.handlers.polygon.tooltip.start = 'Haz click para empezar a dibujar la figura';
                L.drawLocal.draw.handlers.polygon.tooltip.cont = 'Haz click para continuar dibujando la figura';
                L.drawLocal.draw.handlers.polygon.tooltip.end = 'Haz click en el primer punto para cerrar la figura';

                L.drawLocal.edit.handlers.edit.tooltip.text = 'Arrastra los puntos para editar la figura';
                L.drawLocal.edit.handlers.edit.tooltip.subtext = 'Haz click en cancelar para deshacer los cambios';

                L.drawLocal.edit.handlers.remove.tooltip.text = 'Haz click en una figura para borrarla';

                this.map.addControl(drawControl);

                this.map.on('draw:created', (e) => {
                    let coordinates = e.layer._latlngs[0];
                    coordinates = coordinates.map((coordinate) => {
                        return [coordinate.lat, coordinate.lng]
                    })
                    const area = Math.round(L.GeometryUtil.geodesicArea(e.layer.getLatLngs()[0]) / 10000 * 10 ) / 10;

                    let layer = e.layer
                    layer.setStyle({
                        color: 'white',    // Color de línea
                        opacity: 1,         // Opacidad de línea
                        fillColor: 'teal', // Color de relleno
                        // fillOpacity: 0.5  // Opacidad de relleno
                    });
                    
                    
                    this.editableLayers.addLayer(layer);
                    this.$emit('polygon', {coordinates, area})
                });

                // Crear las instancias de las herramientas de dibujo
                this.drawHandler = new L.Draw.Polygon(this.map, {
                    shapeOptions: {
                        color: 'orange', // Color de la línea
                        opacity: 1, // Opacidad de la línea
                        fillColor: 'orange', // Color de relleno
                        // fillOpacity: 0.5 // Opacidad del relleno
                    }
                });
                // this.editHandler = new L.EditToolbar.Edit(this.map, {
                //     featureGroup: this.editableLayers
                // });
            },
            addClustersToMap() {
                // Marcadores
                let sectorMarkers = L.markerClusterGroup({
                    disableClusteringAtZoom: 15, // Ajusta este valor al nivel de zoom en el que quieres que los marcadores se expandan
                    spiderfyOnMaxZoom: false,
                    showCoverageOnHover: false,
                    zoomToBoundsOnClick: false,
                    iconCreateFunction: function (cluster) {
                        return L.divIcon({
                            html: '<div class="leaflet-sector-marker cluster"><i class="fa-solid fa-vector-square"></i>' + cluster.getChildCount() + '</div>',
                            iconSize: [20, 20]
                        });
                    }
                });

                // Agrupar todas las fincas
                let farmMarkers = L.markerClusterGroup({
                    disableClusteringAtZoom: 12, // Ajusta este valor al nivel de zoom en el que quieres que los marcadores se expandan
                    spiderfyOnMaxZoom: false,
                    showCoverageOnHover: false,
                    zoomToBoundsOnClick: false,
                    iconCreateFunction: function (cluster) {
                        return L.divIcon({
                            html: '<div class="leaflet-sector-marker cluster"><i class="fa-solid fa-group-arrows-rotate"></i>' + cluster.getChildCount() + '</div>',
                            iconSize: [20, 20]
                        });
                    }
                });

                let sectorCreateFunction = function () {
                    return L.divIcon({
                        html: '<div class="leaflet-sector-marker cluster"><i class="fa-solid fa-vector-square"></i>1</div>',
                        iconSize: [20, 20]
                    });
                };

                this.sectors.forEach(sector => {
                    let latSum = 0;
                    let lonSum = 0;
                    let coordCount = sector.coordinates.length;

                    sector.coordinates.forEach(coord => {
                        latSum += coord[0];
                        lonSum += coord[1];
                    });

                    let centroid = [latSum / coordCount, lonSum / coordCount];
                    let sectorMarker = L.marker(centroid, { icon: sectorCreateFunction() });
                    sectorMarkers.addLayer(sectorMarker);
                });

                // Entonces puedes crear un marcador para cada finca y añadirlo al featureGroup:
                this.farms.forEach(farm => {
                    if (farm.sectors.length === 0) return;
                    let farmIcon = L.divIcon({
                        html: '<div class="leaflet-farm-marker single"><i class="fa-solid fa-group-arrows-rotate"></i>' + farm.name + '</div>',
                        iconSize: [200, 60]
                    });

                    // Get centroid from sectors in farms
                    let latSum = 0
                    let lonSum = 0
                    let sectorCount = farm.sectors.length;

                    farm.sectors.forEach(sector => {
                        let coordCount = sector.coordinates.length;
                        let latSectorSum = 0
                        let lonSectorSum = 0
                        sector.coordinates.forEach(coord => {
                            latSectorSum += coord[0]
                            lonSectorSum += coord[1]
                        });
                        latSum += latSectorSum / coordCount
                        lonSum += lonSectorSum / coordCount
                    });

                    let centroid = [latSum / sectorCount, lonSum / sectorCount];
                    let farmMarker = L.marker(centroid, { icon: farmIcon })
                    farmMarkers.addLayer(farmMarker)
                });


                this.map.on('zoomend', () => {
                    let zoomLevel = this.map.getZoom();
                    if (zoomLevel >= 15) { // Ajusta este valor al nivel de zoom en el que quieres que los marcadores se expandan
                        this.sectorLayers.forEach(layer => this.map.addLayer(layer))
                        this.imageLayers.forEach(layer => this.map.addLayer(layer))
                        this.map.removeLayer(sectorMarkers)
                        this.map.removeLayer(farmMarkers)
                    } else if (zoomLevel >= 14 && zoomLevel < 15) {
                        this.sectorLayers.forEach(layer => this.map.removeLayer(layer))
                        this.imageLayers.forEach(layer => this.map.removeLayer(layer))
                        this.map.addLayer(sectorMarkers)
                        this.map.removeLayer(farmMarkers);
                    } else {
                        this.sectorLayers.forEach(layer => this.map.removeLayer(layer))
                        this.imageLayers.forEach(layer => this.map.removeLayer(layer))
                        this.map.removeLayer(sectorMarkers)
                        this.map.addLayer(farmMarkers)
                    }
                });
            }
        },
        mounted() {
            this.createMap();
            if (this.farms.length > 0) this.addSectorsToMap();
            this.addEditionModeToMap();
            this.addClustersToMap();
        }
    }
</script>
<style>
    .leafmap {
        z-index: 0;
    }
    .floating-search {
        position: absolute;
        top: 10px;
        left: 10px;
        z-index: 1;
    }
    .floating-zoom {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: 10px;
        z-index: 1;
    }

    .legend {
        position: absolute;
        bottom: 10px;
        right: 10px;
        z-index: 1;
    }

    .leaflet-draw-toolbar {
        display: none !important;
    }

    .leaflet-editing-icon {
        border-radius: 50% !important;
        width: 15px !important;
        height: 15px !important;
        margin-left: -5px !important;
        margin-top: -5px !important;
        background: white !important; 
        border: 5px solid orange !important;
    }

    .leaflet-div-icon {
        background: rgba(0, 84, 66, 0); 
        border: none;
    }

    .cluster {
        background: rgba(1, 7, 10, 0.7);
        border: 0px solid rgb(0, 84, 66);
        color: #DDD;
    }

    .single {
        background: rgba(0, 84, 66, 0.85);
        border: 0px solid rgb(0, 84, 66);
        color: #DDD;
    }

    .leaflet-sector-marker {
        width: 80px; 
        height: 50px; 
        border-radius: 8px;
        font-size: 17px;
        line-height: 40px;
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .leaflet-sector-marker i {
        margin-right: 10px;
    }

    .leaflet-farm-marker {
        border-radius: 8px !important;
        padding: 10px 30px;
        text-align: center;
        white-space: nowrap;
        overflow: hidden;
        max-width: 120px;
        min-height: 50px;
        font-size: 15px;
        display: flex;
        align-items: center;
        justify-content: center;
        transform: translate(50%, 50%);
    }

    .leaflet-farm-marker i {
        margin-right: 10px;
    }

    .leaflet-tooltip {
        font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
        background: rgba(1, 7, 10, 1);
        color: #DDD;
        border: none;
        border-radius: 3px;
        padding: 15px 20px;
        border-radius: 8px;
        font-size: 13px;
        line-height: 23px;
    }

    .leaflet-tooltip h3 {
        padding-bottom: 10px;
        margin-bottom: 10px;
        border-bottom: 1px solid rgba(255, 255, 255, 0.192);
    }

    .leaflet-tooltip .chip {
        color: #DDD !important;
        padding: 3px 10px !important;
        margin-left: 20px !important;
        font-size: 11px !important;
        border-radius: 3px !important;
        font-weight: bold !important;
    }

    .leaflet-tooltip .teal {
        background: rgba(0, 84, 66) !important;
    }

    .leaflet-tooltip .grey {
        background: rgb(65, 65, 65) !important;
    }
</style>
