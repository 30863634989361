<template>
    <router-view/>
</template>

<script>

export default {
    name: "App",
    components: {  },
    data: () => (
        {
            // miniVariant: true,
        }
    ),
    beforeCreate() {
        if (!this.$store.getters["auth/isLoggedIn"]) {
            this.$router.push('/login');
        }
    },
};
</script>

<style>

</style>