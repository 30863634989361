import api from '@/services/api'

export default {
    namespaced: false,
    state: {
        notifications: [],
    },
    getters: {
        notifications: state => state.notifications,
    },
    mutations: {
        setNotification(state, notifications) {
            state.notifications = notifications
        },
    },
    actions: {
        async getNotifications({ commit, dispatch }) {
            await api
            .get(`/notifications`)
            .then(response => {
                if (response.data.success) {
                    commit('setNotification', response.data.notifications)
                } else if (response.status === 401 || response.status === 403) {
                    // console.log(response.data.farms)
                    dispatch('goToLogin')
                }
            })
            .catch(function (error) {
                console.log(error)
                // dispatch('getFarms')
            })
        },
        markAsReadNotification({ commit, dispatch }, notificationId) {
            api
            .post(`/notifications/read/${notificationId}`)
            .then(response => {
                if (response.data.success) {
                    // commit('setFarms', response.data.farms)
                    dispatch('getNotifications')
                }
            })
            .catch(function (error) {
                console.log(error)
                // dispatch('getFarms')
            })
        },
        deleteNotification({ commit, dispatch }, notificationId) {
            api
            .delete(`/notifications/${notificationId}`)
            .then(response => {
                if (response.data.success) {
                    // commit('setFarms', response.data.farms)
                    dispatch('getNotifications')
                } else if (response.status === 401 || response.status === 403) {
                    // console.log(response.data.farms)
                    // dispatch('goToLogin')
                }
            })
            .catch(function (error) {
                console.log(error)
                // dispatch('getFarms')
            })
        },
    },
};