<template>
    <span class="d-flex flex-column fill-height overflow-y-auto" v-if="farms.length>0" style="max-height: 100vh;">
        <v-card
            class="pa-0 fill-height"
            tile
            dark
            
            v-if="!selectedSector || !navigationMode"
        >
            <v-alert
                v-model="downloadingAlert"
                dismissible
                color="teal"
                border="left"
                elevation="5"
                colored-border
                icon="mdi-information-outline"
                class="mx-auto my-0 blue-grey darken-4"
                dark
                >
                    Actualmente los datos se están descargando desde el servidor. Esto puede demorar unos minutos.
            </v-alert>
            <v-card-title class="pa-6">
                Lista de sectores
                <!-- <v-chip x-small label class="ml-3">12.3 ha</v-chip> -->
            </v-card-title>
            <!-- <v-divider></v-divider> -->
            <v-card-text class="pa-0">
                <v-list dense v-for="(farm, farmIdx) in farms" :key="'farm-'+farmIdx">
                    <v-hover v-slot="{ hover }">
                        <v-subheader class="body-2 pl-3 pt-1 blue-grey darken-4">
                            {{ farm.name }}
                            <v-btn icon small class="ml-2 mt-n1" v-show="hover" @click="editFarm(farm)">
                                <v-icon small color="grey lighten-1">mdi-pencil</v-icon>
                            </v-btn>
                            <v-spacer></v-spacer>
                            <v-chip v-show="!hover" x-small label class="mt-n1">{{calculateAreaFromFarm(farm)}} ha</v-chip>
                        </v-subheader>
                    </v-hover>
                    <v-divider></v-divider>
                    <v-list-item v-for="(sector, index) in farm.sectors" :key="index" @click="selectedSector=sector" :input-value="sector == selectedSector">
                        <v-list-item-avatar tile size="60">
                            <ThumbnailMap :coordinates="sector.coordinates" :id="sector.id"></ThumbnailMap>
                        </v-list-item-avatar>
                        <v-list-item-content>
                            <v-list-item-title>
                                {{ sector.name }} 
                                <v-chip label x-small class="ml-5">{{ sector.area }} ha</v-chip>
                                <span class="ml-5" v-if="isSectorDownloading(sector.id)">
                                    <v-icon>mdi-cloud</v-icon>
                                    <v-icon x-small style="margin-left: -18px;" color="black">mdi-autorenew mdi-spin</v-icon>
                                </span>
                            </v-list-item-title>
                            <v-list-item-subtitle>{{ sector.year }}</v-list-item-subtitle>
                        </v-list-item-content>
                        <v-list-item-action>
                            <v-menu dark left min-width="200">
                                <template v-slot:activator="{ on, attrs }">
                                    <v-btn v-bind="attrs" v-on="on" icon>
                                        <v-icon>mdi-dots-vertical</v-icon>
                                    </v-btn>
                                </template>
                                <v-list>
                                    <v-list-item @click="editSector(sector)">
                                        <v-list-item-title>
                                            <v-icon left dense class="mr-3">mdi-pencil</v-icon>
                                            <span class="body-2">Editar</span>
                                        </v-list-item-title>
                                    </v-list-item>
                                    <v-list-item @click="deleteSector(sector)">
                                        <v-list-item-title>
                                            <v-icon left dense class="mr-3" color="red">mdi-delete</v-icon>
                                            <span class="body-2 red--text">Eliminar</span>
                                        </v-list-item-title>
                                    </v-list-item>
                                </v-list>
                            </v-menu>
                        </v-list-item-action>
                    </v-list-item>
                </v-list>
            </v-card-text>
            <v-card-actions class="py-4 grey darken-4">
                <v-btn 
                    block
                    color="primary"
                    class="py-6 rounded-lg"
                    @click="$emit('add-sector')"
                    :disabled="mapEditMode"
                >
                    <v-icon left>mdi-plus</v-icon>
                    Añadir sector
                </v-btn>
            </v-card-actions>
            <EditFarmDialogComponent v-model="editFarmDialog" :farm="editedFarm"/>
            <DeleteSectorDialogComponent v-model="deleteSectorDialog" :sector="editedSector"/>
            <EditSectorDialogComponent v-model="editSectorDialog" :sector="editedSector"/>
        </v-card>
        
        <v-card
            class="pa-0 fill-height blue-grey darken-4"
            tile
            dark
            v-else-if="selectedSector && navigationMode"
        >
            <v-toolbar flat>
                <v-toolbar-title>
                    <v-btn small @click="selectedSector=null">
                        <v-icon left>mdi-chevron-left</v-icon>
                        Atrás
                    </v-btn>
                </v-toolbar-title>
            </v-toolbar>
            <v-card flat tile outlined class="pa-3">
                <v-list-item>
                    <v-list-item-avatar tile size="60">
                        <ThumbnailMap :coordinates="selectedSector?.coordinates" :id="selectedSector?.id"></ThumbnailMap>
                    </v-list-item-avatar>
                    <v-list-item-content>
                        <v-list-item-title>{{ selectedSector?.name }} <v-chip label x-small class="ml-3 mt-n2 pt-1 teal darken-3">{{ farms.find(f => f.id === selectedSector.farmId).name }}</v-chip></v-list-item-title>
                        <v-list-item-subtitle class="caption">{{ selectedSector?.coordinates[0][0].toFixed(5) }}º N {{ selectedSector?.coordinates[0][1].toFixed(5) }}º O</v-list-item-subtitle>
                    </v-list-item-content>
                    <v-list-item-action>
                        <v-chip label x-small class="mr-5">{{ selectedSector.area }} ha</v-chip>
                    </v-list-item-action>
                </v-list-item>
            </v-card>
            <v-card flat tile outlined class="mt-3">
                <v-toolbar>
                    <v-toolbar-title class="subtitle-1">
                        Cambios en el estado del campo
                        <v-tooltip top left max-width="600px" color="transparent">
                            <template v-slot:activator="{ on }">
                                <v-icon v-on="on" dense right>mdi-information</v-icon>
                            </template>
                            <v-card class="pa-3">
                                <v-card-title>Cambios en el estado del índice</v-card-title>
                                <v-card-text>
                                    <span>
                                        <p>Este recuadro muestra la variación en el índice NDVI entre dos fechas específicas.</p>
                                        <ul>
                                            <li><strong>Índice NDVI</strong>: Es una medida de la cantidad y salud de la vegetación. Un valor negativo no necesariamente indica un problema, sin embargo, un cambio grande o inesperado puede ser una señal de que algo no está bien.</li>
                                        </ul><br>
                                        <p>Si la variación del NDVI es negativa, se mostrará un icono de advertencia, mientras que si es positiva, se mostrará un icono de verificación. Recuerde que una variación negativa no implica necesariamente un comportamiento anómalo y podría ser parte del ciclo natural del cultivo.</p>
                                    </span>
                                </v-card-text>
                            </v-card>
                        </v-tooltip>
                        <!-- <v-icon right dense>mdi-information</v-icon> -->
                    </v-toolbar-title>
                </v-toolbar>
                <v-expansion-panels>
                    <v-expansion-panel>
                        <v-expansion-panel-header class="d-flex justify-start pa-4">
                            <span>Evolución de índice:
                                <v-icon :color="indexEvolution.sign ? 'teal' : 'orange'" right dense>
                                    {{ indexEvolution.sign ? 'mdi-check-circle' : 'mdi-alert' }}
                                </v-icon>
                            </span>
                        </v-expansion-panel-header>
                        <v-expansion-panel-content class="pa-1 grey--text text--lighten-1">
                            <v-row class="caption">
                                <span>
                                    <v-icon color="lighten-1" :color="indexEvolution.sign ? 'teal' : 'red'" class="mt-n1" small>{{ indexEvolution.sign ? 'mdi-arrow-up-thin' : 'mdi-arrow-down-thin'}}</v-icon>NDVI
                                </span>
                                <v-spacer></v-spacer>
                                <span class="text--lighten-1" :class="indexEvolution.sign ? 'teal--text' : 'red--text'">{{ indexEvolution.diff }}</span>
                            </v-row>
                        </v-expansion-panel-content>
                        <v-divider></v-divider>
                        <v-expansion-panel-content class="mt-4 pa-3 grey--text text--lighten-1">
                            <v-row class="caption py-3">
                                <span class="white--text">
                                    Periodo del cambio del índice
                                </span>
                                <v-spacer></v-spacer>
                            </v-row>
                            <v-row class="caption pb-3">
                                <span class="text-lowercase">
                                    {{ indexEvolution.start?.day }} de {{ months[indexEvolution.start?.month]?.name }} de {{ indexEvolution.start?.year }}
                                </span>
                                <v-spacer class="mx-4 mt-2" style="border-top: 1px solid grey;"></v-spacer>
                                <span class="text-lowercase">
                                    {{ indexEvolution.end?.day }} de {{ months[indexEvolution.end?.month]?.name }} de {{ indexEvolution.end?.year }}
                                </span>
                            </v-row>
                            <v-row class="caption pb-3">
                                <span>
                                    <v-icon>mdi-cloud</v-icon> {{ indexEvolution.clp0 }}%
                                </span>
                                <v-spacer ></v-spacer>
                                <span>
                                    <v-icon>mdi-cloud</v-icon> {{ indexEvolution.clp1 }}%
                                </span>
                            </v-row>
                            <v-row class="caption pt-3">
                                <div class="d-flex flex-row">
                                    <v-icon small left>mdi-information</v-icon>
                                    <span>Si la nubosidad es mayor al 0% el valor del índice puede ser inválido</span>
                                </div>
                            </v-row>
                        </v-expansion-panel-content>
                    </v-expansion-panel>
                </v-expansion-panels>
            </v-card>
            <v-card flat tile outlined class="mt-3">
                <v-toolbar>
                    <v-toolbar-title class="subtitle-1">
                        Tiempo actual 
                        <v-tooltip top left max-width="600px" color="transparent">
                            <template v-slot:activator="{ on }">
                                <v-icon v-on="on" dense right>mdi-information</v-icon>
                            </template>
                            <v-card class="pa-3">
                                <v-card-title>Clima actual</v-card-title>
                                <v-card-text>
                                    <span>
                                        <p>Este recuadro muestra datos meteorológicos a tiempo real del sector seleccionado, obtenidos de la estación meteorológica más cercana.</p>
                                        <!-- <ul>
                                            <li><strong>Viento</strong>: Muestra la velocidad y dirección del viento en tiempo real.</li>
                                            <li><strong>Humedad</strong>: Indica el porcentaje de humedad en el aire.</li>
                                            <li><strong>Nubes</strong>: Muestra el porcentaje de nubosidad en el cielo.</li>
                                            <li><strong>Precipitaciones</strong>: Indica la cantidad de lluvia caída, medido en milímetros (mm).</li>
                                        </ul><br> -->
                                        <p>Estos datos pueden ser útiles para tomar decisiones de gestión de cultivos y riego en tiempo real.</p>
                                    </span>
                                </v-card-text>
                            </v-card>
                        </v-tooltip>
                        <!-- <v-icon right dense>mdi-information</v-icon> -->
                    </v-toolbar-title>
                </v-toolbar>
                <v-expansion-panels flat>
                    <v-expansion-panel>
                        <v-expansion-panel-header class="d-flex justify-start pa-4">
                            <v-row class="pa-5">
                                <v-col cols="12" class="pa-0">
                                    <span class="subtitle-1">{{ new Date().toLocaleDateString('es-ES', { day: 'numeric', month: 'long', year: 'numeric' }) }}, {{ new Date().toLocaleTimeString('es-ES', { hour: '2-digit', minute: '2-digit' }) }}</span>
                                </v-col>
                                <v-col cols="12" class="pa-0 mt-2">
                                    <div class="d-flex align-center">
                                        <span class="text-h4 pt-2 mr-2">{{ currentData.temp_c }}ºC</span>
                                        <!-- <v-icon color="yellow" class="ml-3 mt-n2" large>mdi-white-balance-sunny</v-icon> -->
                                        <i class="wu wu-white wu-64" :class="weatherIcons[currentData.condition?.code] + (!currentData?.is_day ? ' wu-night' : '')"></i>
                                    </div>
                                </v-col>
                            </v-row>
                        </v-expansion-panel-header>
                        <v-divider></v-divider>
                        <v-expansion-panel-content class="px-1 pt-4 grey--text text--lighten-1">
                            <v-row class="subtitle-2 font-weight-regular grey--text text-lighten-1">
                                <v-col cols="4" class="d-flex justify-center pb-0">
                                    <span>Viento</span>
                                </v-col>
                                <v-col cols="4" class="d-flex justify-center pb-0">
                                    <span>Humedad</span>
                                </v-col>
                                <v-col cols="4" class="d-flex justify-center pb-0">
                                    <span>Nubes</span>
                                </v-col>
                            </v-row>
                            <v-row class="text-h6 font-weight-regular  white--text mt-4">
                                <v-col cols="4" class="d-flex justify-center pt-0">
                                    <span>{{ (currentData.wind_kph / 3.6).toFixed(1) }} m/s
                                         <v-icon color="grey lighten-1">mdi-arrow-top-left</v-icon>
                                    </span>
                                </v-col>
                                <v-col cols="4" class="d-flex justify-center pt-0">
                                    <span>{{ currentData.humidity }} %</span>
                                </v-col>
                                <v-col cols="4" class="d-flex justify-center pt-0">
                                    <span>{{ currentData.cloud }} %</span>
                                </v-col>
                            </v-row>
                        </v-expansion-panel-content>
                        <v-divider></v-divider>
                        <v-expansion-panel-content class="px-1 pt-4 grey--text text--lighten-1">
                            <v-row class="subtitle-2 font-weight-regular grey--text text-lighten-1">
                                <v-col cols="4" class="d-flex justify-center pb-0">
                                    <span>Precipitaciones</span>
                                </v-col>
                            </v-row>
                            <v-row class="text-h6 font-weight-regular white--text mt-4">
                                <v-col cols="4" class="d-flex justify-center pt-0">
                                    <span>{{ currentData.precip_mm }} mm </span>
                                </v-col>
                                <v-col cols="8" class="d-flex caption justify-end align-end pt-0">
                                    <a><span class="teal--text" @click="$router.push(`/forecast/${selectedSector.id}`)">Abrir ventana de pronóstico <v-icon color="teal" x-small>mdi-open-in-new</v-icon></span></a>
                                </v-col>
                            </v-row>
                        </v-expansion-panel-content>
                    </v-expansion-panel>
                </v-expansion-panels>
                <v-overlay :value="loading" :absolute="true">
                    <v-progress-circular
                        indeterminate
                        size="64"
                    ></v-progress-circular>
                </v-overlay>
            </v-card>
        </v-card>
    </span>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import api from '@/services/api'
import ThumbnailMap from '@/components/ThumbnailMap'
import EditFarmDialogComponent from '@/components/EditFarmDialogComponent'
import EditSectorDialogComponent from '@/components/EditSectorDialogComponent'
import DeleteSectorDialogComponent from '@/components/DeleteSectorDialogComponent'
import '@johnmmackey/weather-underground-icons/dist/wu-icons-style.min.css'

export default {
    name: 'SectorsNavigationComponent',
    components: {
        ThumbnailMap, EditFarmDialogComponent, DeleteSectorDialogComponent, EditSectorDialogComponent
    },
    props: {
        value: {
            type: Object,
            default: null,
        },
        mapEditMode: {
            type: Boolean,
            default: false,
        },
        indexEvolution: {
            type: Object,
            default: () => ({
                start: null,
                end: null,
                clp0: null,
                clp1: null,
            }),
        },
        navigationMode: {
            type: Boolean,
            default: false,
        },
        // currentData: {
        //     type: Object,
        //     default: () => ({}),
        // },
    },
    data: () => ({
        loading: false,
        downloadingAlert: false,
        editFarmDialog: false,
        editedFarm: null,
        editSectorDialog: false,
        deleteSectorDialog: false,
        editedSector: null,
        currentData: {},
    }),
    computed: {
        ...mapGetters(['farms', 'months', 'weatherIcons', 'tasksStatus']),
        selectedSector: {
            get() {
                return this.value
            },
            set(value) {
                this.$emit('input', value)
            },
        },
    },
    watch: {
        selectedSector(val) {
            if (val) {
                this.loadData()
            }
        },
        editFarmDialog(val) {
            if (!val) {
                this.editedFarm = null
            }
        },
    },
    methods: {
        ...mapActions(['getSectorsTasksStatus']),
        calculateAreaFromFarm(farm) {
            let area = 0
            farm.sectors.forEach(sector => {
                area += sector.area
            })
            return area.toFixed(1)
        },
        loadData() {
            this.loading = true
            api.get(`/current/${this.selectedSector?.id}`)
            .then(response => {
                this.currentData = response.data.data.current;
                this.loading = false
            })
        },
        editFarm(farm) {
            this.editedFarm = farm
            this.editFarmDialog = true
        },
        editSector(sector) {
            this.editedSector = sector
            this.editSectorDialog = true
        },
        deleteSector(sector) {
            this.editedSector = sector
            this.deleteSectorDialog = true
        },
        isSectorDownloading(sectorId) {
            return this.tasksStatus.find(task => task.sectorId === sectorId && !task.completed)
        },
    },
    async created() {
        if (this.tasksStatus?.length === 0 || !this.tasksStatus) await this.getSectorsTasksStatus()
    },
}
</script>
