import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VSystemBar } from 'vuetify/lib/components/VSystemBar';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VDialog,{attrs:{"max-width":"750px","scrollable":""},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c(VCard,{staticClass:"mx-auto blue-grey darken-4 rounded-lg px-3 py-6",attrs:{"dark":""}},[_c(VSystemBar,{staticClass:"mt-n8",attrs:{"color":"transparent"}},[_c(VSpacer),_c(VBtn,{staticClass:"mt-12 pl-1",attrs:{"text":"","plain":"","icon":""},on:{"click":function($event){_vm.dialog = false}}},[_c(VIcon,{attrs:{"color":"grey lighten-2"},on:{"click":_vm.close}},[_vm._v("mdi-close")])],1)],1),_c(VCardTitle,{staticClass:"mb-8"},[_vm._v("¿Estás seguro de que deseas eliminar el sector \""+_vm._s(_vm.sector?.name)+"\"?")]),_c(VCardActions,{staticClass:"d-flex"},[_c(VBtn,{staticClass:"flex-grow-1 pa-6 elevation-0 rounded-lg elevation-0",on:{"click":_vm.close}},[_vm._v(" Cancelar ")]),_c(VBtn,{staticClass:"flex-grow-1 pa-6 elevation-0 rounded-lg red darken-3 elevation-0",on:{"click":_vm.remove}},[_vm._v(" Eliminar ")])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }