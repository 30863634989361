<template>
    <v-dialog
        v-model="dialog"
        max-width="500px"
        scrollable
    >
        <v-card
            class="mx-auto blue-grey darken-4 rounded-lg px-3 py-6"
            dark
        >
            <v-system-bar color="transparent" class="mt-n8">
                <v-spacer></v-spacer>
                <v-btn  class="mt-12 pl-1" text plain icon @click="dialog = false">
                    <v-icon color="grey lighten-2" @click="close">mdi-close</v-icon>
                </v-btn>
            </v-system-bar>
            <v-card-title class="mb-8">{{ farm ? 'Editar' : 'Añadir' }} finca</v-card-title>
            <v-card-text>
                <div class="mb-8">
                    <v-text-field
                        v-model="editedFarm.name"
                        label="Nombre de la finca"
                        outlined
                        hide-details
                        class="rounded-lg grey darken-4 my-4"
                        rounded
                        dark
                        prepend-inner-icon="mdi-group"
                    ></v-text-field>
                </div>
            </v-card-text>
            <v-card-actions v-if="farm" class="d-flex justify-center">
                <!-- <v-hover v-slot:default="{ hover }">
                    <v-btn
                        class="flex-grow-1 pa-6 elevation-0 rounded-lg elevation-0"
                        color="red darken-3"
                        @click="remove"
                        :disabled="farm.sectors.length > 0"
                        text
                    >
                        Eliminar
                    </v-btn>
                    <v-menu v-model="hover">
                        HOLA
                    </v-menu>
                </v-hover> -->
                <v-tooltip
                top
                :color="farm.sectors.length > 0 ? '' : 'transparent'"
                >
                    <template v-slot:activator="{ on, attrs }">
                        <div v-bind="attrs" v-on="on">
                            <v-btn
                            class="flex-grow-1 pa-6 elevation-0 rounded-lg elevation-0"
                            color="red darken-3"
                            @click="remove"
                            :disabled="farm.sectors.length > 0"
                            text
                            >
                                Eliminar
                            </v-btn>
                        </div>
                    </template>

                    <span v-if="farm.sectors.length > 0">Debes eliminar primero todos los sectores asociados a esta finca</span>
                </v-tooltip>
            </v-card-actions>
            <v-card-actions class="d-flex">
                <v-btn
                    class="flex-grow-1 pa-6 elevation-0 rounded-lg elevation-0"
                    @click="close"
                >
                    Cerrar
                </v-btn>
                <v-btn
                    class="flex-grow-1 pa-6 elevation-0 rounded-lg teal elevation-0"
                    @click="save"
                >
                    Guardar
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import { mapActions } from 'vuex'

export default {
    name: 'EditFarmDialogComponent',
    components: {
    },
    props: {
        value: {
            type: Boolean,
            default: false
        },
        farm: {
            type: Object,
            default: null
        }
    },
    data: () => ({
        editedFarm: {
            name: '',
        },
        defaultFarm: {
            name: '',
        },
    }),
    computed: {
        dialog: {
            get() {
                return this.value
            },
            set(val) {
                this.$emit('input', val)
            }
        },
    },
    watch: {
        farm: {
            immediate: true,
            handler(newValue) {
                this.editedFarm = {...newValue};
            }
        }
    },
    methods: {
        ...mapActions(['insertFarm', 'deleteFarm', 'updateFarm']),
        close() {
            this.dialog = false
        },
        save() {
            if (this.farm) {
                this.updateFarm(this.editedFarm)
            } else {
                this.insertFarm(this.editedFarm)
            }
            this.dialog = false
        },
        remove() {
            this.deleteFarm(this.farm.id)
            this.dialog = false
        }
    },
}
</script>

<style scoped>

</style>
  