import api from '@/services/api'

export default {
    namespaced: false,
    state: {
        images: [],
    },
    getters: {
        images: state => state.images,
    },
    mutations: {
        setImages(state, images) {
            state.images = images
        },
    },
    actions: {
        getImages({ commit, dispatch }) {
            api
            .get(`/images`)
            .then(response => {
                if (response.data.success) {
                    commit('setImages', response.data.images)
                } else if (response.status === 401 || response.status === 403) {
                    // console.log(response.data.images)
                    dispatch('goToLogin')
                }
            })
            .catch(function (error) {
                console.log(error)
                // dispatch('getImages')
            })
        },
    },
};